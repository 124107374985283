import { useContext } from 'react';
import api from '../../../api';
import { LoadingContext } from '../../../context/LoadingContext';

const useRequestFilter = ({ setDataCentral }) => {
  const { setLoading } = useContext(LoadingContext);
  const pathname = window.location.pathname;

  const removeLoading = () => {
    if (pathname === '/central-notificacao') {
      setLoading(false);
    }
  };
  const loadDataCentralNotification = async (payload) => {
    try {
      const response = await api.get('/notificacao', {
        params: {
          ...payload,
        },
      });
      setDataCentral(response.data);
      removeLoading();
    } catch (error) {
      removeLoading();
    }
  };

  return { loadDataCentralNotification };
};

export default useRequestFilter;
