import { FC, useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import { Button, Checkbox, TextField } from '@mui/material';
import { Label } from './components/label';
import { FloatingLabel } from './components/floatingLabel';
import Search from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Option } from '../../types/Option';

interface SelectWithSearchV2Props {
  options: Option[];
  inputSearchPlaceholder?: string;
  inputSearchLabel?: string;
  label?: string;
  required?: boolean;
  selectedOptions: any[];
  setSelected?: (options: Option[]) => void;
  setSelectedOptions: (options: Option[]) => void;
  dataTestSelectAll: string;
  dataTestClearAll: string;
  dataTestIdInputSearch: string;
  disabled: boolean;
  emptyMessage?: string;
  emptyCondition?: boolean;
  requestInSearch?: boolean;
  requestOptionsBySearch?: (text: string) => void;
  loading?: boolean;
}

export const SelectWithSearchV2: FC<any> = ({
  options,
  inputSearchPlaceholder = 'Pesquise...',
  inputSearchLabel = 'Pesquisar',
  label = 'Selecione uma opção',
  required,
  setSelected,
  selectedOptions,
  setSelectedOptions,
  dataTestSelectAll,
  dataTestClearAll,
  dataTestIdInputSearch,
  disabled,
  emptyMessage,
  emptyCondition,
  requestInSearch,
  requestOptionsBySearch,
  loading,
  setValue,
  inputName,
}: any) => {
  if (label === 'Resultado da Citologia') {
  }
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const menuRef = useRef<HTMLDivElement>(null);
  const [valueTextField, setValueTextField] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (optionSelected: Option) => {
    const isSelected = selectedOptions?.find(
      (option) => option?.value === optionSelected?.value
    );
    if (isSelected) {
      setValue(
        inputName,
        selectedOptions.filter((item) => item.value !== optionSelected.value)
      );

      if (setValue) {
        setValue(
          inputName,
          selectedOptions.filter((item) => item.value !== optionSelected.value)
        );
      }
    } else {
      setValue(inputName, [...selectedOptions, optionSelected]);

      if (setValue) {
        setValue(inputName, [...selectedOptions, optionSelected]);
      }
    }
  };

  const handleSelectAll = () => {
    setValue(inputName, options);
  };

  const handleClearSelection = () => {
    setValue(inputName, []);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    if (selectedOptions?.length > 1) {
      setValueTextField('Vários');
    }

    if (selectedOptions?.length === 1) {
      setValueTextField(selectedOptions[0].label);
    }

    if (selectedOptions?.length === 0) {
      setValueTextField('');
    }
  }, [selectedOptions]);

  return (
    <div className={style.container}>
      <TextField
        fullWidth
        size="small"
        label={label}
        disabled={disabled}
        value={valueTextField}
        InputProps={{
          endAdornment: (
            <Button
              data-testid="modalEstadoDropdownButton"
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={disabled}
              // disabled={
              //   disableInputEstado || isPadrao || isEstadual || isMunicipal
              // }
              onClick={(event) => {
                toggleMenu();
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',

                  color: disabled ? '#BDBDBD' : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />

      {/* <div
        data-testid={dataTestIdModal}
        className={style.selectedValues}
        onClick={toggleMenu}
        tabIndex={0}
      >
        {selectedOptions?.length === 0 && !isOpen ? (
          <Label label={label} required={required} />
        ) : (
          <FloatingLabel label={label} required={required} menuOpen={isOpen} />
        )}

        <div>
          {selectedOptions?.length > 1 && 'Vários'}

          {selectedOptions?.length === 1 && selectedOptions[0].label}

          {selectedOptions?.length === 0 && ''}
        </div>

        {isOpen ? (
          <ArrowDropUpIcon fontSize="medium" />
        ) : (
          <ArrowDropDownIcon fontSize="medium" />
        )}
      </div> */}
      {isOpen && (
        <div className={style.optionsMenu} ref={menuRef}>
          <TextField
            data-testid={dataTestIdInputSearch}
            type="text"
            placeholder={inputSearchPlaceholder}
            label={inputSearchLabel}
            value={filterText}
            onChange={(e) => {
              if (requestInSearch && requestOptionsBySearch) {
                requestOptionsBySearch(filterText);
              }
              setFilterText(e.target.value);
            }}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <Search sx={{ fontSize: '20px', color: 'rgba(0,0,0,0.5)' }} />
              ),
            }}
          />

          <div className={style.optionButtons}>
            <button
              type="button"
              onClick={handleSelectAll}
              data-testid={dataTestSelectAll}
            >
              Selecionar todos
            </button>{' '}
            <div>-</div>
            <button
              type="button"
              onClick={handleClearSelection}
              data-testid={dataTestClearAll}
            >
              Limpar
            </button>
          </div>

          <div className={style.optionsList}>
            {emptyCondition ? (
              <label>{emptyMessage}</label>
            ) : (
              <>
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <>
                    {filteredOptions.map((option, index) => {
                      return (
                        <label key={option.value}>
                          <Checkbox
                            value={option.value}
                            checked={
                              !!selectedOptions?.find((optionSelected) => {
                                return optionSelected.value === option.value;
                              })
                            }
                            onChange={() => handleOptionSelect(option)}
                            data-testid={`option-${index + 1}`}
                          />
                          {option.label}
                        </label>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
