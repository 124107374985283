// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsCitologiaReflexo,

//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   showTableData,
//   setShowTableData,
//   setTableData,
//   setErrorsFields,
//   setDataColetaIni,
//   setDataColetaFinal,
//   setSelectedResultado,
//   setSelectedOptions,
//   setPayload,
//   setSelectedLaboratorio,
//   setSelectedOptionsLaboratorio,
//   setListFilterShow,
// }) => {
//   const fieldsToClearWhenIsPadrao: any[] = [
//     'resultado',
//     'profissionalLaudo',
//     'data_ini',
//     'data_fin',
//     'search',
//   ];

//   const fieldsToClearWhenSuperAdmin: any[] = [
//     ...fieldsToClearWhenIsPadrao,
//     'estado',
//     'municipio',
//     'estabelecimento_saude',
//   ];

//   const fieldsToClearWhenIsMunicipal: any[] = [
//     ...fieldsToClearWhenIsPadrao,

//     'estabelecimento_saude',
//   ];

//   const fieldsToClearWhenEstadual = [
//     'municipio',
//     ...fieldsToClearWhenIsMunicipal,
//   ];

//   const userToken = getUserToken();
//   const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
//   const isEstadual = userToken.permissao_atual.nome === 'Admin Estadual';
//   const isMunicipal = userToken.permissao_atual.nome === 'Admin Municipal';
//   const isAdminLaboratorio =
//     userToken.permissao_atual.nome === 'Admin Estabelecimento';
//   const isPadraoEstabelecimento =
//     userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';

//   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//     setListFilterShow((prev) =>
//       prev.map((item) =>
//         item.inputName === inputName ? { ...item, value: newValue } : item
//       )
//     );
//   };

//   const clearValues = () => {
//     setErrorsFields([]);
//     setTableData([]);
//     setShowTableData(false);
//     setDataColetaIni(null);
//     setDataColetaFinal(null);

//     if (isPadraoEstabelecimento) {
//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//     }

//     if (isMunicipal) {
//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//     }

//     if (isEstadual || isAdminLaboratorio) {
//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//     }

//     if (isSuperAdmin) {
//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);
//     }
//   };
//   // const handleSetFilterToShow = (newValue: any, inputName: string) => {
//   //   setListFilterShow((prev) =>
//   //     prev.map((item) =>
//   //       item.inputName === inputName ? { ...item, value: newValue } : item
//   //     )
//   //   );
//   // };

//   // const clearValues = async () => {
//   //   setErrorsFields([]);
//   //   setTableData({ data: [] });

//   //   setDataColetaIni(null);
//   //   setDataColetaFinal(null);
//   //   setDataColetaFinal(null);
//   //   setDataColetaIni(null);
//   //   setSelectedResultado([]);
//   //   setSelectedOptions([]);

//   //   const fieldsToClearWhenIsPadrao: any[] = [
//   //     'resultado',
//   //     'profissionalLaudo',
//   //     'data_ini',
//   //     'data_fin',
//   //     'search',
//   //   ];

//   //   const fieldsToClearWhenIsPadraoLaboratorio: any[] = [
//   //     'resultado',
//   //     'estabelecimento_saude',
//   //     'data_ini',
//   //     'data_fin',
//   //     'search',
//   //   ];

//   //   const fieldsToClearWhenSuperAdmin: any[] = [
//   //     ...fieldsToClearWhenIsPadrao,
//   //     'estado',
//   //     'municipio',
//   //     'estabelecimento_saude',
//   //   ];

//   //   const fieldsToClearWhenIsMunicipal: any[] = [
//   //     ...fieldsToClearWhenIsPadrao,

//   //     'estabelecimento_saude',
//   //   ];

//   //   const fieldsToClearWhenEstadual = [
//   //     'municipio',
//   //     ...fieldsToClearWhenIsMunicipal,
//   //   ];

//   //   const permissaoId = getUserToken().permissao_atual.id;
//   //   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//   //     setListFilterShow((prev) =>
//   //       prev.map((item) =>
//   //         item.inputName === inputName ? { ...item, value: newValue } : item
//   //       )
//   //     );
//   //   };
//   //   const isPadrao =
//   //     permissaoId === 1 ||
//   //     permissaoId === 9 ||
//   //     permissaoId === 10 ||
//   //     permissaoId === 7;

//   //   const isLaboratorio =
//   //     getUserToken().permissao_atual.contexto === 'LABORATÓRIO';

//   //   const isMunicipal = permissaoId === 3;
//   //   const isEstadual = permissaoId === 4;

//   //   if (isMunicipal) {
//   //     fieldsToClearWhenIsMunicipal.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setEstabelecimentosSelected([]);
//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estado: payload.estado,
//   //           municipioId: payload.municipioId,
//   //           tipo: 'Reflexo',
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (isPadrao && !isLaboratorio) {
//   //     setValue('profissionalResultadoId', null);

//   //     fieldsToClearWhenIsPadrao.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setShowTableData(true);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estado: payload.estado,
//   //           tipo: 'Reflexo',

//   //           municipioId: payload.municipioId,
//   //           estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
//   //           // laboratorioLaudoId: payload.laboratorioLaudoId,
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (isPadrao && isLaboratorio) {
//   //     fieldsToClearWhenIsPadraoLaboratorio.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //       setMunicipiosSelected([]);
//   //       setEstabelecimentosSelected([]);
//   //     });
//   //     setShowTableData(true);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estados: payload.estado,
//   //           tipo: 'Reflexo',
//   //           profissionalResultadoId: payload.profissionalResultadoId,
//   //           laboratorioLaudoId: payload.laboratorioLaudoId,
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (permissao === 'isSuperAdmin') {
//   //     fieldsToClearWhenSuperAdmin.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     setShowTableData(false);
//   //     setEstabelecimentosSelected([]);
//   //     setMunicipiosSelected([]);
//   //     setEstadosSelected([]);

//   //     // const payload = {
//   //     //   size: watch().size,
//   //     //   page: watch().page,
//   //     // };

//   //     // if (showTableData) {
//   //     //   loadReportsHpv(payload);
//   //     // }
//   //   }

//   //   if (isEstadual) {
//   //     fieldsToClearWhenEstadual.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setShowTableData(false);
//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     setEstabelecimentosSelected([]);
//   //     setMunicipiosSelected([]);

//   //     // const payload = {
//   //     //   size: watch().size,
//   //     //   page: watch().page,
//   //     // };

//   //     // if (showTableData) {
//   //     //   loadReportsHpv(payload);
//   //     // }
//   //   }
//   // };

//   return { clearValues };
// };

// export default useClearValues;

// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsTrackingHpv,
//   setTableData,
//   setUsePayloadFromCharts,
//   showTableData,
//   setShowTableData,
//   clearErrors,
//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   setAcsSelected,
//   setListFilterToShow,
//   listFilter,
//   getReportsTracking,
// }) => {
//   const fieldsToClearWhenIsPadrao: any[] = [
//     'acs',
//     'idade_inicio',
//     'idade_final',
//     'faixa',
//     'prioridade',
//     'meio_rastreio',
//     'search',
//     'has_teste',
//     'periodo',
//   ];

//   const fieldsToClearWhenIsMunicipal = [
//     'estabelecimento_saude',
//     ...fieldsToClearWhenIsPadrao,
//   ];

//   const fieldsToClearWhenEstadual = [
//     'municipio',
//     ...fieldsToClearWhenIsMunicipal,
//   ];

//   const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

//   const { currentEstablishment } = useContext(EstablishmentContext);

//   const clearValues = ({
//     setPeriod,
//     setValueInputPeriod,
//     setValuePeriodoModal,
//     setValueInputQuadrimestreSemestre,
//     setYear,
//     setSelectedInicialAge,
//     setSelectedFinalAge,
//     setSelectedFaixa,
//     setSelectedPrioridade,
//     setSelectedMeio,
//     setSelectedRealizacaoTeste,
//   }) => {
//     setPeriod(null);
//     setValueInputPeriod(null);
//     setValuePeriodoModal(null);
//     setValueInputQuadrimestreSemestre(null);
//     setYear(null);

//     setSelectedInicialAge([]);
//     setSelectedFinalAge([]);
//     setSelectedFaixa([]);
//     setSelectedPrioridade([]);
//     setSelectedMeio([]);
//     setSelectedRealizacaoTeste([]);

//     clearErrors([]);
//     setTableData([]);
//     setShowTableData(false);

//     const handleSetFilterToShow = (newValue: any, inputName: string) => {
//       setListFilterToShow((prev) =>
//         prev.map((item) =>
//           item.inputName === inputName ? { ...item, value: newValue } : item
//         )
//       );
//     };
//     const userToken = getUserToken();
//     const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
//     const isEstadual = userToken.permissao_atual.nome === 'Admin Estadual';
//     const isMunicipal = userToken.permissao_atual.nome === 'Admin Municipal';
//     const isAdminLaboratorio =
//       userToken.permissao_atual.nome === 'Admin Estabelecimento';
//     const isPadraoEstabelecimento =
//       userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';

//     const {
//       estabelecimentoId: estabelecimentoIdUser,
//       estabelecimento_saude: {
//         nome_fantasia: estabelecimentoNameUser,
//         endereco: {
//           municipio: {
//             id: municipioUserId,
//             nome: municipioUserName,
//             estadoSigla: estadoUserSigla,
//           },
//         },
//       },
//     } = userToken;
//     if (isPadraoEstabelecimento) {
//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         estabelecimentoSaudeId: estabelecimentoIdUser,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isMunicipal) {
//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isEstadual || isAdminLaboratorio) {
//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//     }

//     if (isSuperAdmin) {
//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);
//     }
//   };

//   return { clearValues };
// };

// export default useClearValues;

// // const clearValues = () => {
// //   setShowTableData(false);
// //   clearErrors();
// //   setUsePayloadFromCharts(false);
// //   setTableData([]);

// //   if (permissao === 'isPadrao') {
// //     fieldsToClearWhenIsPadrao.forEach((field) => {
// //       setValue(field, null);
// //       handleSetFilter('', field);
// //     });

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment.endereco.municipio.nome,
// //       estabelecimentoId: currentEstablishment.id,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isMunicipal') {
// //     fieldsToClearWhenIsMunicipal.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment.endereco.municipio.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isEstadual') {
// //     fieldsToClearWhenEstadual.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isSuperAdmin') {
// //     fieldsToClearWhenSuperAdmin.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstadosSelected([]);
// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }
// // };

import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';
import useClearReports from '../../../../../utils/hooks/useClearReports';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsCitologiaReflexo,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  initialStateListFilter,
  setListFilterShow,

  setSelectedResultado,
  setDataColetaFinal,
  setDataColetaIni,
  setSelectedLaboratorio,
  setSelectedOptions,
  setSelectedOptionsLaboratorio,
}) => {
  const { clearReport } = useClearReports({ setListFilterShow });
  const userToken = getUserToken();

  const {
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
  } = userToken;

  const objStatesToClear = {
    setSelectedResultado,
    setSelectedLaboratorio,
    setSelectedOptions,
    setSelectedOptionsLaboratorio,
  };

  const fieldNamesToClear = initialStateListFilter.map((fieldObj) => {
    return fieldObj.inputName;
  });

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual.nome === 'Admin Estabelecimento';
  const isMedicoLaboratorio = userToken.permissao_atual.id === 7;
  const isPadraoUnidadeSaude =
    userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);
    setDataColetaFinal('');
    setDataColetaIni('');

    const clearWhenSuperAdmin = () => {
      setShowTableData(false);

      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
          setEstadosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear,
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenEstadual = () => {
      setShowTableData(false);
      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(1),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenAdminLaboratorio = () => {
      delete objStatesToClear.setSelectedOptionsLaboratorio;
      delete objStatesToClear.setSelectedLaboratorio;
      setShowTableData(false);
      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'estabelecimentoLaudoId'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        'estabelecimentoLaudoId'
      );
    };

    const clearWhenMedicoLaboratorio = () => {
      delete objStatesToClear.setSelectedOptionsLaboratorio;
      delete objStatesToClear.setSelectedLaboratorio;
      setShowTableData(false);
      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'estabelecimentoLaudoId')
          .filter((field) => field !== 'profissionalResultadoId'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        'estabelecimentoLaudoId'
      );
    };

    const clearWhenMunicipal = () => {
      clearReport({
        objStates: {
          ...objStatesToClear,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(2),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        page: 1,
        size: 25,
      };

      loadReportsCitologiaReflexo({ payload });
    };

    const clearWhenPadraoUnidadeSaude = () => {
      clearReport({
        objStates: {
          ...objStatesToClear,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(3),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        estabelecimentoSaudeId: estabelecimentoIdUser,
        page: 1,
        size: 25,
      };

      loadReportsCitologiaReflexo({ payload });
    };

    if (isSuperAdmin) {
      clearWhenSuperAdmin();
    }

    if (isEstadual) {
      clearWhenEstadual();
    }

    if (isAdminLaboratorio) {
      clearWhenAdminLaboratorio();
    }

    if (isMedicoLaboratorio) {
      clearWhenMedicoLaboratorio();
    }

    if (isPadraoUnidadeSaude) {
      clearWhenPadraoUnidadeSaude();
    }

    if (isMunicipal) {
      clearWhenMunicipal();
    }
  };

  return { clearValues };
};

export default useClearValues;
