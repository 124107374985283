import { toast } from 'react-toastify';
import api from '../../api';

export const getReportsConsolidado = async ({ payload }) => {
  try {
    const response = await api.get('/reports/visualizar/consolidado', {
      params: {
        ...payload,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'bottom-right',
    });
  }
};
