import { Tooltip } from '@mui/material';
import './styles.scss';
import AlertInfo from '../../../assets/imgs/AlertInfo.svg';

const CardConvocated = ({ convocacaoPosition, value, titleTooltip }) => {
  return (
    <div className="card_convocated">
      <span>
        Agendadas <br />
        {convocacaoPosition}
      </span>
      <p>{value}</p>
      <div className="card_convocated__tooltip">
        <Tooltip title={titleTooltip} placement="top">
          <img src={AlertInfo} alt="" style={{ marginLeft: '13px' }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default CardConvocated;
