const useClearReports = ({ setListFilterShow }) => {
  const clearReport = ({
    objStates,
    valuesFormToClear,
    fnSetValue,
    handleSetFilter,
    handleSetFilterToShow,
    clearAllDisplayShowed,
  }: any) => {
    for (const fn in objStates) {
      objStates[fn]([]);
    }

    valuesFormToClear.forEach((field) => {
      fnSetValue(field, '');
      handleSetFilter('', field);
      if (clearAllDisplayShowed) {
        return;
      }
      handleSetFilterToShow('', field);
    });

    if (clearAllDisplayShowed) {
      setListFilterShow([]);
    }
  };

  return { clearReport };
};

export default useClearReports;
