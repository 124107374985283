import React, { useCallback } from 'react';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function CustomTablePagination(props) {
  const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange, tableRef, ...other } = props;

  const scrollToTable = useCallback(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [tableRef]);

  const handleChangePage = useCallback((event, newPage) => {
    onPageChange(event, newPage);
    scrollToTable();
  }, [onPageChange, scrollToTable]);

  const handleChangeRowsPerPage = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(event);
    onPageChange(null, 0); // Reset para a primeira página
    scrollToTable();
  }, [onRowsPerPageChange, onPageChange, scrollToTable]);

  const CustomPaginationActions = useCallback(({ onPageChange, ...rest }) => (
    <div style={{ display: 'flex' }}>
      <IconButton
        onClick={(e) => handleChangePage(e, 0)}
        disabled={page === 0}
      >
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={(e) => handleChangePage(e, page - 1)}
        disabled={page === 0}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={(e) => handleChangePage(e, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        onClick={(e) => handleChangePage(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </div>
  ), [page, count, rowsPerPage, handleChangePage]);

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={CustomPaginationActions}
      {...other}
    />
  );
}

export default CustomTablePagination;
