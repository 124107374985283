import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsHistopatologia } from '../../../../../lib/Reports/getReportsHistopatologia';

const useRequestFilter = ({ setTableData, setLoadingTable }) => {
  const { setLoading } = useContext(LoadingContext);

  const loadReportsHistopatologia = async ({ payload }) => {
    setLoadingTable(true);
    const response = await getReportsHistopatologia({
      payload,
    });

    setTableData(response);
    setLoadingTable(false);
    setLoading(false);
  };

  return {
    loadReportsHistopatologia,
  };
};

export default useRequestFilter;
