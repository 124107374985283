import moment from 'moment';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  selectedResultado,
  dtColetaFinal,
  dtColetaIni,
  dtResultadoIni,
  dtResultadoFinal,
  selectedGlandular,
  selectedEscamosa,
  selectedCarater,
  selectedOrigem,

  selectedOptionsAvaliacao,
  selectedOptionsVisibilidade,

  selectedOptionsZt,

  selectedOptionsTiposAchados,
  selectedOptionsLugol,
  selectedOptionsSchiller,
  selectedOptionsBiopsia,

  dtColposcopiaIni,
  dtColposcopiaFinal,
  selectedLaboratorio,
  selectedOptionsAlteracoes,
}: any) => {
  const setPayload = () => {
    const estados = estadosSelected.map((estado) => {
      if (estado === 'Sem Vinculação') {
        return 'null';
      } else {
        return estado;
      }
    });
    const arrayLab = selectedLaboratorio?.map((lab) => lab.value);
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estado: estados.length > 0 ? estados.join(', ') : undefined,
        municipioId:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,

        estabelecimento:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,

        laboratorioId: arrayLab?.length > 0 ? arrayLab.join(', ') : undefined,
        exame_macroscopico: selectedOptionsAlteracoes
          ?.map((item) => item.value)
          .join(', '),
        avaliacao_geral: selectedOptionsAvaliacao
          ?.map((item) => item.value)
          .join(', '),

        juncao_escamocolunar: selectedOptionsVisibilidade
          ?.map((item) => item.value)
          .join(', '),
        zona_zt: selectedOptionsZt?.map((item) => item.value).join(', '),
        tipo_achados: selectedOptionsTiposAchados
          ?.map((item) => item.value)
          .join(', '),
        lugol: selectedOptionsLugol?.map((item) => item.value).join(', '),
        teste_schiller: selectedOptionsSchiller
          ?.map((item) => item.value)
          .join(', '),
        realizar_biopsia: selectedOptionsBiopsia
          ?.map((item) => item.value)
          .join(', '),

        profissionalId:
          watch().profissional_responsavel_laudo?.idprofissional ?? undefined,

        periodo_inicio: dtColposcopiaIni
          ? moment(dtColposcopiaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        periodo_fim: dtColposcopiaFinal
          ? `${moment(dtColposcopiaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')}`
          : undefined,

        motivo: watch().motivo ?? undefined,
        search: watch().search ?? undefined,
        size: watch().size,
        page: watch().page,
      };
      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
