import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  const headers = [
    // Dados Pessoais
    { label: 'Nome Completo\nda Mulher', field: 'nomeCompletoMulher' },
    { label: 'Nº do Cartão SUS', field: 'numeroCartaoSus' },
    { label: 'Nome Completo\nda Mãe', field: 'nomeCompletoMae' },
    { label: 'CPF', field: 'cpf' },
    { label: 'Data de\nNascimento', field: 'dataNascimento' },
    { label: 'Idade Atual', field: 'idade' },
    { label: 'Idade no Momento\ndo Teste de HPV', field: 'idadeHpv' },
    { label: 'Nacionalidade', field: 'nacionalidade' },
    { label: 'Raça / Cor', field: 'racaCor' },

    // Endereço
    { label: 'CEP', field: 'cep' },
    { label: 'Logradouro', field: 'logradouro' },
    { label: 'Número', field: 'numero' },
    { label: 'Complemento', field: 'complemento' },
    { label: 'Bairro', field: 'bairro' },
    { label: 'Ponto Referência', field: 'pontoReferencia' },
    { label: 'UF', field: 'uf' },
    { label: 'Município', field: 'municipio' },
    {
      label: 'Estabelecimento\nVinculado da\nPaciente',
      field: 'estabelecimentoVinculadoPaciente',
    },
    // { label: 'INE da Equipe\nVinculada', field: 'ineEquipeVinculada' },

    // Teste HPV
    { label: 'Atendimento do\nTeste de HPV', field: 'atendimentoTesteHpv' },
    {
      label: 'Motivo do Não\nAtendimento do\nTeste de HPV',
      field: 'motivoNaoAtendimentoTesteHpv',
    },
    { label: 'Data da Coleta do\nTeste de HPV', field: 'dataColetaTesteHpv' },
    { label: 'Status do Teste de\nHPV', field: 'dataResultadoTesteHpv' },
    {
      label: 'Data do Resultado\ndo Teste de HPV',
      field: 'dataResultadoTesteHpv',
    },
    { label: 'Resultado do\nTeste de HPV', field: 'resultadoTesteHpv' },
    { label: 'Tipo de Vírus\nHPV 16', field: 'tipoVirusHpv16' },
    { label: 'Tipo de Vírus\nHPV 18', field: 'tipoVirusHpv18' },
    { label: 'Tipo de Vírus:\nOutros', field: 'tipoVirusHpvOutros' },

    // Citologia
    {
      label: 'Data do Resultado\nda Citologia',
      field: 'dataResultadoCitologia',
    },
    { label: 'Resultado da\nCitologia', field: 'resultadoCitologia' },

    // Colposcopia
    { label: 'Data da\nColposcopia', field: 'dataColposcopia' },
    { label: 'Tipo de Achados', field: 'tipoAchados' },
    { label: 'Local', field: 'local' },
    {
      label: 'Localização em\nrelação a Zona de\nTransformação\n(ZT)',
      field: 'zonaTransformacao',
    },
    { label: 'N° de quadrantes\nenvolvidos', field: 'nQuadrantes' },
    { label: 'Grau 1 (Menor)', field: 'grau1Menor' },
    { label: 'Grau 2 (Maior)', field: 'grau2Maior' },
    { label: 'Não específico', field: 'naoEspecifico' },
    { label: 'Suspeita de\ninvasão', field: 'suspeitaInvasao' },
    { label: 'Realizar\nBiópsia', field: 'realizarBiopsia' },

    // Histopatologia
    {
      label: 'Data da Coleta da\nHistopatologia',
      field: 'dataColetaHistopatologia',
    },
    { label: 'Origem da\nHistopatologia', field: 'origemHistopatologia' },
    { label: 'Margens\nCirúrgicas', field: 'margensCirurgicas' },
    {
      label: 'Data do Resultado\nda Histopatologia',
      field: 'dataResultadoHistopatologia',
    },
    { label: 'Lesões de Caráter\nBenigno', field: 'lesoesCaracterBenigno' },
    { label: 'Pré-Neoplásico\nEscamosa', field: 'preNeoplasicaEscamosa' },
    { label: 'Pré-Neoplásico\nGlandular', field: 'preNeoplasicaGlandular' },
    { label: 'Outras Neoplasias\nMalignas', field: 'outrasNeoplasiasMalignas' },
    {
      label: 'Data da\nRealização do\nTratamento',
      field: 'dataRealizacaoTratamento',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map(({ label, field }) => (
          <TableCell
            key={field}
            align="left"
            sx={{
              borderBottom: 'none',
              whiteSpace: 'pre-line',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                width: '160px',
              }}
              fontWeight="bold"
            >
              {label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
