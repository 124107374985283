import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TabPanelProps } from '../../../components/BoxInfoPatient/BoxInfoPatient';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { getEstados } from '../../../lib/getEstados';
import { getWindowDimensions } from '../../../utils/getWindowDimensions';
import { VisaoGeral } from './tabs/VisaoGeral';

interface Estado {
  id: string | number;
  nome: string;
  sigla: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const CitologiaReflexoPanel = () => {
  const [loading, setLoading] = React.useState(false);
  const [estados, setEstados] = React.useState<Estado[]>([]);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [tabSize, setTabSize] = React.useState('33%');
  const [value, setValue] = React.useState(0);

  const loadEstados = async () => {
    const response = await getEstados();
    setEstados(response);
    // setLoading(false);
  };

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setTabSize('100%');
    } else {
      setTabSize('33%');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  React.useEffect(() => {
    loadEstados();
  }, []);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Painel de Exames de Citologia - Reflexo
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography color="text.primary">Paineis</Typography>

                  <Typography key="3" color="text.primary">
                    Painel de Exames de Citologia - Reflexo
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>

            <Divider />

            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                marginBottom: '-25px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <TabPanel value={value} index={0}>
              <VisaoGeral />
            </TabPanel>
          </Box>
        </Container>
      </SideMenu>
    );
  }
};
