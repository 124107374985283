export const optionsResultadoHPV = [
  { value: 'INVALIDO', label: 'Inconclusivo' },
  {
    value: 'NEGATIVO_INDETECTADO',
    label: 'Não Detectável',
  },
  {
    value: 'POSITIVO',
    label: 'Detectável',
  },
];

export const optionsTiposVirus = [
  { value: 'hpv_16', label: 'HPV 16' },
  {
    value: 'hpv_18',
    label: 'HPV 18',
  },
  {
    value: 'hpv_outros',
    label: 'Outros',
  },
];

export const optionsMotivoExame = [
  { value: 'RASTREAMENTO', label: 'Rastreamento' },
  {
    value: 'REPETICAO',
    label: 'Repetição (exame alterado ASCUS/Baixo grau)',
  },
  {
    value: 'SEGUIMENTO',
    label: 'Seguimento (pós diagnóstico colposcópico / tratamento)',
  },
];
export const optionsInspecao = [
  { value: 'NORMAL', label: 'Normal' },
  {
    value: 'NAO_VISUALIZADO',
    label: 'Colo não visualizado',
  },
  {
    value: 'ALTERADO',
    label: 'Alterado',
  },
  {
    value: 'AUSENTE',
    label: 'Ausente (anomalias congênitas ou retirado cirurgicamente)',
  },
];

export const optionsMotivoNaoAtendida = [
  { value: 'NAO_REALIZOU', label: 'Compareceu mas não realizou o exame' },
  {
    value: 'MENSTRUADA',
    label: 'Estava Menstruada',
  },
  {
    value: 'NAO_FAIXA_ETARIA',
    label: 'Não está na faixa etária',
  },
  {
    value: 'HISTERECTOMIZADA',
    label: 'Histerectomizada por patologia benigna',
  },
  {
    value: 'OUTRO',
    label: 'Outro',
  },
];

export const optionsAtendimentoTesteHPV = [
  { value: 'REALIZADO', label: 'Realizado' },
  { value: 'NAO_REALIZOU', label: 'Não Realizou' },
];

export const optionsTipoAchados = [
  { value: 'NORMAIS', label: 'Achado Colposcópico Normal' },
  { value: 'ANORMAIS', label: 'Achado Colposcópico Anormal' },
  // { value: 'AMBOS', label: 'Ambos' },
];

export const optionsResultadoCitologia = [
  { value: 'Com Alterações Epiteliais', label: 'Com Alterações Epiteliais' },
  { value: 'Insatisfatório', label: 'Insatisfatório' },
  { value: 'Negativo', label: 'Negativo' },
];

export const optionsRealizarBiopsia = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não', label: 'Não' },
];

export const optionsOrigemHistopatologia = [
  { value: 'BIOPSIA', label: 'Biópsia' },
  { value: 'PECA_CIRURGICA', label: 'Peça Cirúrgica' },
];

export const optionsPreNeoplasicaEscamosa = [
  { value: 'NICI', label: 'NIC I (Displasia leve)' },
  { value: 'NICII', label: 'NIC II (Displasia moderada)' },
  {
    value: 'NICIII',
    label: 'NIC III (Displasia acentuada / Carcinoma in situ)',
  },
  {
    value: 'CARCINOMA_MICROINVASIVO',
    label: 'Carcinoma Epidermóide Microinvasivo',
  },
  { value: 'CARCINOMA_INVASIVO', label: 'Carcinoma Epidermóide Invasivo' },
  {
    value: 'CARCINOMA_EPIDERMOIDE',
    label: 'Carcinoma Epidermóide impossível avaliar invasão',
  },
];

export const optionsPreNeoplasicaGlandular = [
  { value: 'ADENOCARCINOMA_IN_SITU', label: 'Adenocarcinoma in situ' },
  { value: 'ADENOCARCINOMA_INVASOR', label: 'Adenocarcinoma invasor' },
];

export const optionsIndicacaoEZT = [
  {
    value: 'TERAPEUTICA_BIOPSIA',
    label: 'Terapêutica: Biópsia prévia de alto grau',
  },
  {
    value: 'TERAPEUTICA_VER_TRATAR',
    label: 'Terapêutica: Critérios ver e tratar',
  },
  {
    value: 'TERAPEUTICA_INVESTIGACAO',
    label: 'Terapêutica: Investigação do canal',
  },
];

export const optionsStatusAtendimento = [
  { value: 'REALIZADO', label: 'Realizado' },
  { value: 'NAO_REALIZADO', label: 'Não Realizou' },
];

export const optionsProcedimento = [
  { value: 'TESTE_HPV', label: 'Teste de HPV' },
  { value: 'CITOLOGIA', label: 'Citologia' },
  { value: 'COLPOSCOPIA', label: 'Colposcopia' },
  { value: 'HISTOPATOLOGIA', label: 'Histopatologia' },
];
