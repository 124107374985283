import { useState } from 'react';

const useStateFilters = () => {
  const [selectedInicialAge, setSelectedInicialAge] = useState<any>([]);
  const [selectedFinalAge, setSelectedFinalAge] = useState<any>([]);
  const [selectedFaixa, setSelectedFaixa] = useState<any>([]);
  const [selectedPrioridade, setSelectedPrioridade] = useState<any>([]);
  const [selectedMeio, setSelectedMeio] = useState<any>([]);
  const [selectedRealizacaoTeste, setSelectedRealizacaoTeste] = useState<any>(
    []
  );
  return {
    selectedInicialAge,
    setSelectedInicialAge,
    selectedFinalAge,
    setSelectedFinalAge,
    selectedFaixa,
    setSelectedFaixa,
    selectedPrioridade,
    setSelectedPrioridade,
    selectedMeio,
    setSelectedMeio,
    selectedRealizacaoTeste,
    setSelectedRealizacaoTeste,
  };
};

export default useStateFilters;
