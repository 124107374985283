import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsHistopatologia } from '../../../../../lib/Reports/getReportsHistopatologia';
import { getReportsColposcopia } from '../../../../../lib/Reports/getReportsColposcopia';

const useRequestFilter = ({ setTableData, setLoadingTable }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsColposcopia = async ({ payload }) => {
    setLoadingTable(true);
    try {
      const response = await getReportsColposcopia({
        payload,
      });

      setTableData(response);
      setLoadingTable(false);

      setLoading(false);
    } catch (error) {}
  };

  return {
    loadReportsColposcopia,
  };
};

export default useRequestFilter;
