import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../api.js';
import BoxInfoPatient from '../../components/BoxInfoPatient/BoxInfoPatient';
import { ModalNewRegister } from '../../components/ModalNewRegister';
import { SideMenu } from '../../components/SideMenu';
import Timeline from '../../components/Timeline';
import { ActionsContainer, ButtonNew } from './SeePatient.style';
import ReturnButton from '../../components/ReturnButton';
import { PerfilContext } from '../../context/PerfilContext';
import { LoadingContext } from '../../context/LoadingContext';
import { getUserToken } from '../../lib/auth';

export default function SeePatient() {
  const [patientData, setPatientData] = React.useState<any>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [examData, setExamData] = React.useState<any>([]);
  const [registerType, setRegisterType] = React.useState<string>('');
  const [canCreateHpv, setCanCreateHpv] = React.useState<any>();
  const { hasPermission } = React.useContext(PerfilContext);

  const validateRegisterHpv = async () => {
    const response = await api.get(`/hpv/validate/${id}`);
    setCanCreateHpv(response.data);
  };

  const handleModalOpen = (registerType: string) => {
    validateRegisterHpv();
    setRegisterType(registerType);
    setModalOpen(true);
  };

  let navigate = useNavigate();

  const { id } = useParams();
  const { setLoading } = React.useContext(LoadingContext);
  React.useEffect(() => {
    setLoading(true);
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPatientData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });

      hasPermission('VIEW_EXAME') &&
        (await api
          .get('/pacientes/' + id + '/exames')
          .then((response) => {
            setExamData(response.data.exames);
          })
          .catch((err) => {
            console.error('ops! ocorreu um erro' + err);
          }));

      setLoading(false);
    }
    loadData(id);
  }, [id]);

  // const novoExame = () => {
  //   navigate(`/pacientes/${patientData.id}/exam`, { state: patientData });
  // };

  const editPaciente = () => {
    navigate(`/pacientes/${patientData.id}/edit`, {
      state: { ...patientData, url: window.location.pathname },
    });
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/pacientes" />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '5px' }}
            data-testid="ficha-paciente"
          >
            Ficha da Paciente
          </Typography>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/pacientes"
            >
              Listagem de Pacientes
            </Link>
            <Typography color="text.primary">Ficha da Paciente</Typography>
          </Breadcrumbs>
          <BoxInfoPatient
            data={patientData}
            buttonEditar={editPaciente}
            viewButton={true}
          ></BoxInfoPatient>
          {hasPermission('VIEW_EXAME') && (
            <Paper
              variant="outlined"
              data-testid="exame-box"
              sx={{ borderRadius: '10px', marginTop: '22px' }}
            >
              {hasPermission('CREATE_EXAME') && (
                <>
                  <Box p={3}>
                    <ActionsContainer display="flex" alignItems="center">
                      <Typography variant="subtitle1" fontWeight="bold">
                        Novo Registro:
                      </Typography>

                      {/* <ButtonNew
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => navigate('anamnese', { state: patientData })}
                    >
                      Anamnese
                    </ButtonNew> */}

                      {(hasPermission('CREATE_EXAME_CITOLOGIA') ||
                        hasPermission('CREATE_EXAME_HPV')) && (
                        <ButtonNew
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => handleModalOpen('rastreio')}
                          data-testid="rastreioButton"
                        >
                          Rastreio
                        </ButtonNew>
                      )}

                      {hasPermission('CREATE_EXAME_COLPOSCOPIA') && (
                        <ButtonNew
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => handleModalOpen('diagnostico')}
                          data-testid="diagnosticoButton"
                        >
                          Diagnostico
                        </ButtonNew>
                      )}

                      {hasPermission('CREATE_EXAME_INTRAEPITELIAL') && (
                        <ButtonNew
                          variant="contained"
                          startIcon={<AddIcon />}
                          data-testid="tratamentoButton"
                          onClick={() =>
                            navigate('tratamento-lesoes-intraepiteliais', {
                              state: patientData,
                            })
                          }
                        >
                          Tratamento
                        </ButtonNew>
                      )}

                      {/* <ButtonNew variant="contained" startIcon={<AddIcon />} onClick={() => handleModalOpen('tratamento')}>
                      Tratamento
                    </ButtonNew> */}
                    </ActionsContainer>
                  </Box>
                  <Box px={3} my={1}>
                    <Divider />
                  </Box>
                </>
              )}

              {/* <Box p={3}>
                <Typography variant="h5" fontWeight="bold" mb={4}>
                  Próximos Passos Sugeridos
                </Typography>
                <CardTimeline
                  label='Segerido para'
                  suggestion
                />
              </Box> */}

              <Timeline />
            </Paper>
          )}
        </Box>
        <ModalNewRegister
          canCreateHpv={canCreateHpv}
          open={modalOpen}
          setOpen={setModalOpen}
          registerType={registerType}
          patientData={patientData}
        />
      </Container>
    </SideMenu>
  );
}
