import { formatedMultiples } from '../../../../../utils/requestUtils/formatMultiples';

const useSetPayload = ({
  watch,
  period,

  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  acsSelected,
  selectedInicialAge,
  selectedFinalAge,
  selectedPrioridade,
  selectedMeio,
  selectedRealizacaoTeste,
  selectedFaixa,
}) => {
  const setPayloadFromChartToPaginate = ({ payloadFromCharts, page, size }) => {
    let payload: any;
    return new Promise<void>((resolve, reject) => {
      payload = {
        ...payloadFromCharts,
        page: page,
        size: size,
      };

      resolve(payload);
    });
  };

  const setPayload = () => {
    const estados = estadosSelected.map((estado) => {
      if (estado === 'Sem Vinculação') {
        return 'null';
      } else {
        return estado;
      }
    });
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estados: estados.length > 0 ? estados.join(', ') : undefined,
        municipioId:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        estado: watch('estado'),
        faixa: formatedMultiples(selectedFaixa),
        profissionalAcsId:
          acsSelected.length > 0 ? acsSelected.join(', ') : undefined,
        idade_inicio: watch().idade_inicio,
        idade_final: watch().idade_final,
        rastreio:
          selectedMeio.length === 2 ? null : formatedMultiples(selectedMeio),
        prioridade:
          selectedPrioridade.length === 2
            ? null
            : formatedMultiples(selectedPrioridade),
        has_teste: formatedMultiples(selectedRealizacaoTeste),
        has_procedimento: watch().has_procedimento,
        periodo_inicio: period?.periodo_inicio,
        periodo_fim: period?.periodo_fim,
        // has_teste:

        search: watch().search,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload, setPayloadFromChartToPaginate };
};

export default useSetPayload;
