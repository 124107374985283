import { getReportsTracking } from '../../../../../lib/Reports/getReportsTracking';

const useRequestFilter = ({ setTableData }) => {
  const loadReportsTrackingHpv = async ({ payload }) => {
    const response = await getReportsTracking({
      payload,
    });

    setTableData(response);
  };

  return {
    loadReportsTrackingHpv,
  };
};

export default useRequestFilter;
