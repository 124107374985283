import Alert from '@mui/material/Alert';

const EmptyData = ({ needsRequiredFilters }) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          height: '48px',
          backgroundColor: needsRequiredFilters ? '#FDE5E5' : '#e5f6fd',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '14px',
          borderRadius: '4px',
          marginTop: '85px',
          padding: '0 16px 0 16px',
        }}
      >
        <Alert
          severity={needsRequiredFilters ? 'error' : 'info'}
          sx={{
            padding: '0',
            backgroundColor: needsRequiredFilters ? '#FDE5E5' : '',
          }}
        />
        {needsRequiredFilters
          ? 'Preencha os filtros obrigatórios'
          : 'Não há dados disponíveis'}
      </div>
    </div>
  );
};

export default EmptyData;
