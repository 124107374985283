import moment from 'moment';
import './styles.scss';
import { Grid } from '@mui/material';

const CardStatusResults = ({
  status,
  updatedAt,
  total,
  color,
  titleDatatest,
  dateDatatest,
  valueDatatest,
}) => {
  const dataInfo = updatedAt
    ? moment(updatedAt).format('DD/MM/YYYY [às] HH:mm')
    : '';
  return (
    <Grid item xs={12} lg={4}>
      <div className={`cardStatusResultsHpv ${color}`}>
        <p className="cardStatusResultsHpv__title" data-testid={titleDatatest}>
          Teste de HPV - Amostra <br />
          {status}*
        </p>

        <p
          className="cardStatusResultsHpv__updatedAt"
          data-testid={dateDatatest}
        >
          Data da Atualização: {dataInfo}
        </p>

        <p className="cardStatusResultsHpv__total" data-testid={valueDatatest}>
          {total}
        </p>

        <div className="cardStatusResultsHpv__originData">
          Fonte de Dados: GAL
        </div>
      </div>
    </Grid>
  );
};

export default CardStatusResults;
