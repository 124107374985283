import './ItemGraphicStyles.scss';

const ItemGraphic = ({
  label,
  value1,
  value2,
  disableGraphicsOnHover,
  removeBorder,
}: any) => {
  const total = value1 + value2;
  const percent1 = (value1 / total) * 100;
  const percent2 = (value2 / total) * 100;

  const hasNoValue = value1 === 0 && value2 === 0;

  return (
    <div className={`itemGraphic ${removeBorder ? 'remove-border' : ''}`}>
      <p>{label}</p>
      <div className="itemGraphic__boxGraphic" style={{ width: 354 }}>
        {hasNoValue ? (
          <>
            <div
              className={`itemGraphic__boxGraphic__chartDisabled `}
              style={{
                backgroundColor: '#d3d3d3',
                width: '100%',

                transition: '0.1s',
              }}
            >
              {value1}
            </div>
          </>
        ) : (
          <>
            <div
              className={`itemGraphic__boxGraphic__chartColorized `}
              style={{
                backgroundColor: '#17BEBB',
                width: value1 > 0 ? `${Math.round(percent1)}%` : '0px',
                opacity: disableGraphicsOnHover === 2 ? '0.5' : '',
                transition: '0.1s',
              }}
            >
              {value1}
            </div>

            <div
              className={`itemGraphic__boxGraphic__chartColorized `}
              style={{
                backgroundColor: '#FFB547',
                width: value2 > 0 ? `${Math.round(percent2)}%` : '0px',
                opacity: disableGraphicsOnHover === 1 ? '0.5' : '',
                transition: '0.1s',
              }}
            >
              {value2}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ItemGraphic;
