import { TableBody, TableRow, TableCell } from '@mui/material';

const Rows = ({ tableData }) => {
  if (!tableData?.data?.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={36} align="center">
            Nenhum resultado encontrado
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {tableData.data.map((row, index) => (
        <TableRow key={index} hover>
          {/* Dados Pessoais */}
          <TableCell>{row.nomeCompletoMulher}</TableCell>
          <TableCell>{row.numeroCartaoSus}</TableCell>
          <TableCell>{row.nomeCompletoMae}</TableCell>
          <TableCell>{row.cpf}</TableCell>
          <TableCell>{row.dataNascimento}</TableCell>
          <TableCell>{row.idade} anos</TableCell>
          <TableCell>{row.idadeTesteHpv} anos</TableCell>
          <TableCell>{row.nacionalidade || '-'}</TableCell>
          <TableCell>{row.racaCor || '-'}</TableCell>

          {/* Endereço */}
          <TableCell>{row.cep || '-'}</TableCell>
          <TableCell>{row.logradouro || '-'}</TableCell>
          <TableCell>{row.numero || '-'}</TableCell>
          <TableCell>{row.complemento || '-'}</TableCell>
          <TableCell>{row.bairro || '-'}</TableCell>
          <TableCell>{row.pontoReferencia || '-'}</TableCell>
          <TableCell>{row.uf}</TableCell>
          <TableCell>{row.municipio}</TableCell>
          <TableCell>{row.estabelecimentoVinculadoPaciente || '-'}</TableCell>
          {/* <TableCell>{row.ineEquipeVinculada || '-'}</TableCell> */}

          {/* HPV */}
          <TableCell>{row.atendimentoTesteHpv || '-'}</TableCell>
          <TableCell>{row.motivoNaoAtendimentoTesteHpv || '-'}</TableCell>
          <TableCell>{row.dataColetaTesteHpv || '-'}</TableCell>
          <TableCell>{row.statusTesteHpv || '-'}</TableCell>
          <TableCell>{row.dataResultadoTesteHpv || '-'}</TableCell>
          <TableCell>{row.resultadoTesteHpv || '-'}</TableCell>
          <TableCell>{row.tipoVirusHpv16 ? 'Sim' : 'Não'}</TableCell>
          <TableCell>{row.tipoVirusHpv18 ? 'Sim' : 'Não'}</TableCell>
          <TableCell>{row.tipoVirusHpvOutros ? 'Sim' : 'Não'}</TableCell>

          {/* Citologia */}
          <TableCell>{row.dataResultadoCitologia || '-'}</TableCell>
          <TableCell>{row.resultadoCitologia || '-'}</TableCell>

          {/* Colposcopia */}
          <TableCell>{row.dataColposcopia || '-'}</TableCell>
          <TableCell>{row.tipoAchados || '-'}</TableCell>
          <TableCell>{row.local || '-'}</TableCell>
          <TableCell>{row.zonaTransformacao || '-'}</TableCell>
          <TableCell>{row.nQuadrantes || '-'}</TableCell>
          <TableCell>{row.grau1Menor || '-'}</TableCell>
          <TableCell>{row.grau2Maior || '-'}</TableCell>
          <TableCell>{row.naoEspecifico || '-'}</TableCell>
          <TableCell>{row.suspeitaInvasao || '-'}</TableCell>
          <TableCell>{row.realizarBiopsia || '-'}</TableCell>

          {/* Histopatologia */}
          <TableCell>{row.dataColetaHistopatologia || '-'}</TableCell>
          <TableCell>{row.origemHistopatologia || '-'}</TableCell>
          <TableCell>{row.margensCirurgicas || '-'}</TableCell>
          <TableCell>{row.dataResultadoHistopatologia || '-'}</TableCell>
          <TableCell>{row.lesoesCaracterBenigno || '-'}</TableCell>
          <TableCell>{row.preNeoplasicaEscamosa || '-'}</TableCell>
          <TableCell>{row.preNeoplasicaGlandular || '-'}</TableCell>
          <TableCell>{row.outrasNeoplasiasMalignas || '-'}</TableCell>
          <TableCell>{row.dataRealizacaoTratamento || '-'}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default Rows;