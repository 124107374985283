import { validarCPF } from './validateCPF';

export const validateSearch = ({ watch, setError, clearErrors }) => {
  let inputSearchIsValid: any;
  const verifyingIfAllCharactersInputSearchIsNumber = (str) => {
    const isAllNumbers = /^[0-9]+$/.test(str);
    return isAllNumbers;
  };

  const inputValueWithoutEspecialChar = watch('search')?.replace(
    /[^a-zA-Z0-9]/g,
    ''
  );
  if (
    inputValueWithoutEspecialChar?.length < 11 &&
    verifyingIfAllCharactersInputSearchIsNumber(inputValueWithoutEspecialChar)
  ) {
    setError('search', {
      type: 'manual',
      message:
        'Número de dígitos incompletos, digite 11 números para CPF ou 15 para CNS',
    });

    inputSearchIsValid = false;
  } else if (
    inputValueWithoutEspecialChar?.length === 11 &&
    verifyingIfAllCharactersInputSearchIsNumber(
      inputValueWithoutEspecialChar
    ) &&
    validarCPF(inputValueWithoutEspecialChar)
  ) {
    inputSearchIsValid = true;
    clearErrors('search');
  } else if (
    inputValueWithoutEspecialChar?.length === 11 &&
    verifyingIfAllCharactersInputSearchIsNumber(
      inputValueWithoutEspecialChar
    ) &&
    !validarCPF(inputValueWithoutEspecialChar)
  ) {
    setError('search', {
      type: 'manual',
      message: 'Digite um CPF válido.',
    });
  } else if (
    inputValueWithoutEspecialChar?.length > 11 &&
    inputValueWithoutEspecialChar?.length < 15 &&
    verifyingIfAllCharactersInputSearchIsNumber(inputValueWithoutEspecialChar)
  ) {
    setError('search', {
      type: 'manual',
      message:
        'Número de dígitos incompletos, digite 11 números para CPF ou 15 para CNS',
    });
  } else if (
    inputValueWithoutEspecialChar?.length === 15 &&
    verifyingIfAllCharactersInputSearchIsNumber(inputValueWithoutEspecialChar)
  ) {
    inputSearchIsValid = true;
    clearErrors('search');
  } else if (
    inputValueWithoutEspecialChar > 15 &&
    verifyingIfAllCharactersInputSearchIsNumber(inputValueWithoutEspecialChar)
  ) {
    setError('search', {
      type: 'manual',
      message:
        'Número de dígitos excedentes, digite 11 números para CPF ou 15 para CNS',
    });
  } else if (
    !verifyingIfAllCharactersInputSearchIsNumber(inputValueWithoutEspecialChar)
  ) {
    inputSearchIsValid = true;
    clearErrors('search');
  }

  return inputSearchIsValid;
};
