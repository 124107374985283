import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';
import ReturnButton from '../../../components/ReturnButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import FiltersReportsTrackingHpv from './FiltersReportsTrackingHpv';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import api from '../../../api';
import TableReportsTracking from '../../../components/TableReports/TableReportsTracking/TableReportsTracking';
import useGetDetailsFilters from './FiltersReportsTrackingHpv/hooks/useGetDetailsFilters';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './FiltersReportsTrackingHpv/schema';
import { LoadingContext } from '../../../context/LoadingContext';
import { getUserToken } from '../../../lib/auth';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useHandleChangesFilters from './FiltersReportsTrackingHpv/hooks/useHandleChangesFilters';
import useStateFilters from './FiltersReportsTrackingHpv/hooks/useStateFilters';
import useSetFilterByPerfil from '../../../utils/hooks/useSetFilterByPerfil';
import { getReportsCitologiaReflexo } from '../../../lib/Reports/getReportsCtiologiaReflexo';
import useRequestFilter from './FiltersReportsTrackingHpv/hooks/useRequestFilter';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'acs', value: '' },
  { inputName: 'idade_inicio', value: '' },
  { inputName: 'idade_final', value: '' },
  { inputName: 'faixa', value: '' },
  { inputName: 'prioridade', value: '' },
  { inputName: 'meio_rastreio', value: '' },
  { inputName: 'search', value: '' },
  { inputName: 'has_teste', value: '' },
  { inputName: 'periodo', value: '' },
];

export const ReportsTrackingHpv = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [payloadFromCharts, setPayloadFromCharts] = useState<any[]>();
  const [usePayloadFromCharts, setUsePayloadFromCharts] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);

  const location = useLocation();

  const origin = location?.state?.origin;
  const payload = location?.state?.payload;
  const clickedCategory = location?.state?.clickedCategory;
  const clickedSeries = location?.state?.clickedSeries;
  const tableOrigin = location?.state?.table;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const {
    estadosSelected,
    setEstadosSelected,
    municipiosSelected,
    setMunicipiosSelected,
    estabelecimentosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    disableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    optionsMunicipios,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
  } = useStatesFields();

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState(
    []
  );

  const {
    selectedInicialAge,
    setSelectedInicialAge,
    selectedFinalAge,
    setSelectedFinalAge,
    selectedFaixa,
    setSelectedFaixa,
    selectedPrioridade,
    setSelectedPrioridade,
    selectedMeio,
    setSelectedMeio,
    selectedRealizacaoTeste,
    setSelectedRealizacaoTeste,
  } = useStateFilters();

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const getReportsTracking = async (payloadToRequest) => {
    const response = await api.get('/reports/visualizar/rastreamento', {
      params: {
        ...payloadToRequest,
      },
    });
    setTableData(response.data);
    if (response.data) {
      setLoading(false);
    }
  };

  const { handleSetFilter } = useHandleChangesFilters({
    setOptionsMunicipios,
    setValue,
    watch,
    setListFilter,
  });

  // const setFiltersToRequestByTable = () => {
  //   return new Promise(async (resolve, reject) => {
  //     let payloadToRequest: any = {
  //       periodo_inicio: payload.periodo_inicio,
  //       periodo_fim: payload.periodo_fim,
  //       idade_inicio:
  //         tableOrigin === 'faixa' ? clickedSeries.split('_')[0] : undefined,
  //       idade_final:
  //         tableOrigin === 'faixa' ? clickedSeries.split('_')[1] : undefined,
  //       page: 1,
  //       size: 20,
  //     };

  //     const filteredByState = !!payload.estados;
  //     const filteredByMunicipio = !!payload.municipios;
  //     const filteredByEstabelecimento = !!payload.estabelecimentos;
  //     const filteredByAcs = !!payload.profissionais;
  //     if (clickedCategory.length === 2) {
  //       // Quando não há filtro aplicado ao gráfico
  //       payloadToRequest = {
  //         ...payloadToRequest,

  //         estado: clickedCategory,
  //         has_teste:
  //           clickedSeries === 'has_hpv' || clickedSeries === 'other_hpv'
  //             ? true
  //             : false,
  //         rastreio:
  //           clickedSeries === 'has_hpv' ||
  //           clickedSeries === 'need_hpv' ||
  //           tableOrigin === 'faixa'
  //             ? 'listagem'
  //             : 'outros',
  //       };
  //       setPayloadFromCharts(payloadToRequest);
  //       resolve(payloadToRequest);
  //     }

  //     // Quando há somente filtro por estado aplicado ao gráfico
  //     if (
  //       filteredByState &&
  //       !filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const municipioData = await getMunicipioDetails(clickedCategory);

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: municipioData[0].id,
  //         estado: municipioData[0].estadoSigla,
  //         has_teste:
  //           clickedSeries === 'has_hpv' || clickedSeries === 'other_hpv'
  //             ? true
  //             : false,
  //         rastreio:
  //           clickedSeries === 'has_hpv' ||
  //           clickedSeries === 'need_hpv' ||
  //           tableOrigin === 'faixa'
  //             ? 'listagem'
  //             : 'outros',
  //       };
  //       setPayloadFromCharts(payloadToRequest);

  //       resolve(payloadToRequest);
  //     }

  //     // Quando há somente filtro por estado e municipio aplicado ao gráfico

  //     if (
  //       filteredByState &&
  //       filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const estabelecimentoData = await getEstabelecimentoDetails(
  //         clickedCategory
  //       );

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: estabelecimentoData.data[0].endereco.municipio.id,
  //         estado: estabelecimentoData.data[0].endereco.municipio.estadoSigla,
  //         estabelecimentoSaudeId: estabelecimentoData.data[0].id,
  //         has_teste:
  //           clickedSeries === 'has_hpv' || clickedSeries === 'other_hpv'
  //             ? true
  //             : false,
  //         rastreio:
  //           clickedSeries === 'has_hpv' ||
  //           clickedSeries === 'need_hpv' ||
  //           tableOrigin === 'faixa'
  //             ? 'listagem'
  //             : 'outros',
  //       };
  //       setPayloadFromCharts(payloadToRequest);

  //       resolve(payloadToRequest);
  //     }

  //     if (
  //       (filteredByState && filteredByMunicipio && filteredByEstabelecimento) ||
  //       filteredByAcs
  //     ) {
  //       const acsData = await getAcsDetails(clickedCategory);

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId:
  //           acsData.data[0].estabelecimentos[0].estabelecimento.endereco
  //             .municipio.id,
  //         estado:
  //           acsData.data[0].estabelecimentos[0].estabelecimento.endereco
  //             .municipio.estadoSigla,
  //         estabelecimentoSaudeId:
  //           acsData.data[0].estabelecimentos[0].estabelecimento.id,
  //         profissionalAcsId: acsData.data[0].id,
  //         has_teste:
  //           clickedSeries === 'has_hpv' || clickedSeries === 'other_hpv'
  //             ? true
  //             : false,
  //         rastreio:
  //           clickedSeries === 'has_hpv' ||
  //           clickedSeries === 'need_hpv' ||
  //           tableOrigin === 'faixa'
  //             ? 'listagem'
  //             : 'outros',
  //       };
  //       setPayloadFromCharts(payloadToRequest);

  //       resolve(payloadToRequest);
  //     }
  //   });
  // };

  const { setLoading } = useContext(LoadingContext);

  const userToken = getUserToken();

  // const setFiltersWhenPadrao = async (userToken) => {
  //   setShowTableData(true);
  //   setDisableInputEstado(true);
  //   setDisabledMunicipioInput(true);
  //   setDisabledEstabelecimentoInput(true);
  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionCidade = [
  //       {
  //         id: userToken.endereco.municipio_id,

  //         nome: userToken.endereco.municipio_nome,
  //         codigo: userToken.endereco.municipio_code,
  //         estadoSigla: userToken.endereco.estado,
  //       },
  //     ];

  //     setOptionsMunicipios(optionCidade);

  //     setMunicipiosSelected([optionCidade[0].id]);

  //     setEstabelecimentosSelected([userToken.estabelecimentoId]);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //       municipios: optionCidade[0].id,
  //       estabelecimentoSaudeId: userToken.estabelecimentoId,
  //     };

  //     handleSetFilter('Estado: PE', 'estados');
  //     handleSetFilter(
  //       `Municipio: ${userToken.endereco.municipio_nome}`,
  //       'municipios'
  //     );
  //     handleSetFilter(
  //       `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
  //       'estabelecimento_saude'
  //     );

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //       {
  //         inputName: 'municipios',
  //         value: `Municipio: ${userToken.endereco.municipio_nome}`,
  //       },
  //       {
  //         inputName: 'estabelecimento_saude',
  //         value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
  //       },
  //     ]);
  //     resolve(payload);
  //   });
  // };

  // const setFiltersWhenMunicipal = async (userToken) => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);
  //   setDisabledMunicipioInput(true);

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const municipio = await getMunicipioDetails(
  //       userToken.endereco.municipio_nome
  //     );

  //     setMunicipiosSelected([municipio[0].id]);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //       municipios: municipio[0].id,
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //       {
  //         inputName: 'municipios',
  //         value: `Municipio: ${userToken.endereco.municipio_nome}`,
  //       },
  //     ]);

  //     // reject();
  //     setLoading(false);
  //   });
  // };

  // const setFiltersWhenEstadual = async (userToken) => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //     ]);

  //     handleSetFilter('Estado: PE', 'estados');
  //     setLoading(false);

  //     // reject();
  //   });
  // };

  // const requestDataByUserPerfil = async () => {
  //   if (
  //     userToken.permissao_atual.id === 1 ||
  //     userToken.permissao_atual.id === 6 ||
  //     userToken.permissao_atual.id === 7 ||
  //     userToken.permissao_atual.id === 8 ||
  //     userToken.permissao_atual.id === 9 ||
  //     userToken.permissao_atual.id === 10
  //   ) {
  //     setFiltersWhenPadrao(userToken).then((payload) => {
  //       getReportsTracking(payload);
  //     });
  //   }

  //   if (userToken.permissao_atual.id === 3) {
  //     setFiltersWhenMunicipal(userToken);
  //   }

  //   if (userToken.permissao_atual.id === 4) {
  //     setFiltersWhenEstadual(userToken);
  //   }

  //   if (userToken.permissao_atual.id === 2) {
  //     setLoading(false);
  //   }

  //   return true;
  // };

  // useEffect(() => {
  //   if (origin === 'tables') {
  //     setLoading(true);
  //     setFiltersToRequestByTable().then((payloadToRequest) => {
  //       getReportsTracking(payloadToRequest);
  //       setUsePayloadFromCharts(true);
  //     });

  //     window.history.replaceState({}, '');
  //   } else {
  //     setLoading(true);
  //     requestDataByUserPerfil();
  //   }
  // }, [origin]);

  // const {
  //   estabelecimentoId: estabelecimentoIdUser,
  //   estabelecimento_saude: {
  //     nome_fantasia: estabelecimentoNameUser,
  //     endereco: {
  //       municipio: {
  //         id: municipioUserId,
  //         nome: municipioUserName,
  //         estadoSigla: estadoUserSigla,
  //       },
  //     },
  //   },
  //   // endereco: {
  //   //   municipio_id: municipioUserId,
  //   //   municipio_nome: municipioUserName,
  //   // },
  // } = userToken;

  // const setDefaultEstado = ({ sigla }) => {
  //   setEstadosOptions([
  //     {
  //       sigla: sigla,
  //     },
  //   ]);
  //   handleSetFilter(`Estado: ${sigla}`, 'estado');

  //   setEstadosSelected([sigla]);
  // };

  // const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
  //   setOptionsMunicipios({
  //     id: municipioId,
  //     nome: municipioNome,
  //   });
  //   handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
  //   setMunicipiosSelected([municipioId]);
  // };

  // const setDefaultEstabelecimento = ({
  //   estabelecimentoId,
  //   estabelecimentoNome,
  // }) => {
  //   setOptionsEstabelecimentos({
  //     id: estabelecimentoIdUser,
  //     municipio: municipioUserName,
  //     municipio_id: municipioUserId,
  //     nome: estabelecimentoNameUser,
  //     label: estabelecimentoNameUser,
  //   });

  //   setEstabelecimentosSelected([estabelecimentoIdUser]);
  // };

  // const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
  // const isEstadual = userToken.permissao_atual.nome === 'Admin Estadual';
  // const isMunicipal = userToken.permissao_atual.nome === 'Admin Municipal';
  // const isAdminLaboratorio =
  //   userToken.permissao_atual.nome === 'Admin Estabelecimento';
  // const isPadraoEstabelecimento =
  //   userToken.permissao_atual.contexto === 'UNIDADE DE SAÚDE';

  // const actionsWhenUserIsEstadual = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  // };

  // const actionsWhenUserIsMunicipal = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  //   setDefaultMunicipio({
  //     municipioId: String(municipioUserId),
  //     municipioNome: municipioUserName,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //   ]);

  //   setShowTableData(true);
  // };

  // const actionsWhenUserIsAdminLaboratorio = () => {
  //   setDefaultEstado({
  //     sigla: userToken.estabelecimento_saude.endereco.municipio.estado.sigla,
  //   });
  // };

  // const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
  //   actionsWhenUserIsMunicipal();

  //   setDefaultEstabelecimento({
  //     estabelecimentoId: estabelecimentoIdUser,
  //     estabelecimentoNome: estabelecimentoNameUser,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //     {
  //       inputName: 'estabelecimento_saude',
  //       value: `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
  //     },
  //   ]);
  // };

  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     return;
  //   }

  //   if (isEstadual) {
  //     actionsWhenUserIsEstadual();
  //   }

  //   if (isMunicipal) {
  //     actionsWhenUserIsMunicipal();

  //     getReportsTracking({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       page: 1,
  //       size: 25,
  //     });
  //   }

  //   if (isAdminLaboratorio) {
  //     actionsWhenUserIsAdminLaboratorio();
  //   }

  //   if (isPadraoEstabelecimento) {
  //     actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

  //     getReportsTracking({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       estabelecimentoSaudeId: estabelecimentoIdUser,
  //       page: 1,
  //       size: 25,
  //     });
  //   }
  // }, []);

  const { loadReportsTrackingHpv } = useRequestFilter({ setTableData });

  const { loadFiltersByPerfil } = useSetFilterByPerfil({
    loadReports: loadReportsTrackingHpv,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    setSelectedOptionsLaboratorio: setSelectedOptionsLaboratorio,
    setShowTableData: setShowTableData,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
  });

  useEffect(() => {
    loadFiltersByPerfil();
  }, []);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton
            backPage={origin === 'tables' ? '/rastreamento/hpv' : '/home'}
          />
          <Typography
            variant="h4"
            fontWeight="bold"
            data-testid="reportsTrackingHpvTitle"
            style={{ marginBottom: '30px' }}
          >
            Relatório de Rastreamento para Testes de HPV
          </Typography>

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
            sx={{ marginBottom: '24px' }}
          >
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/home"
            >
              Home
            </Link>
            <Typography color="text.primary">
              {origin === 'tables'
                ? 'Painel de Rastreamento para Testes de HPV'
                : 'Relatórios'}
            </Typography>

            <Typography key="3" color="text.primary">
              Relatório de Rastreamento para Testes de HPV
            </Typography>
          </Breadcrumbs>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <FiltersReportsTrackingHpv
              setListFilterShow={setListFilterShow}
              initialStateListFilter={initialStateListFilter}
              getReportsTracking={getReportsTracking}
              setListFilterToShow={setListFilterShow}
              disableInputEstado={disableInputEstado}
              setListFilter={setListFilter}
              listFilter={listFilter}
              listFilterShow={listFilterShow}
              setPeriod={setPeriod}
              period={period}
              setTableData={setTableData}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              origin={origin}
              tableOrigin={tableOrigin}
              clickedCategory={clickedCategory}
              clickedSeries={clickedSeries}
              payloadCharts={payload}
              setUsePayloadFromCharts={setUsePayloadFromCharts}
              showTableData={showTableData}
              setShowTableData={setShowTableData}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              selectedInicialAge={selectedInicialAge}
              selectedFinalAge={selectedFinalAge}
              selectedPrioridade={selectedPrioridade}
              selectedMeio={selectedMeio}
              selectedRealizacaoTeste={selectedRealizacaoTeste}
              selectedFaixa={selectedFaixa}
              setSelectedFaixa={setSelectedFaixa}
              setSelectedFinalAge={setSelectedFinalAge}
              setSelectedInicialAge={setSelectedInicialAge}
              setSelectedMeio={setSelectedMeio}
              setSelectedPrioridade={setSelectedPrioridade}
              setSelectedRealizacaoTeste={setSelectedRealizacaoTeste}
            />

            <TableReportsTracking
              selectedFaixa={selectedFaixa}
              period={period}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              payloadFromCharts={payloadFromCharts}
              setPayloadFromCharts={setPayloadFromCharts}
              usePayloadFromCharts={usePayloadFromCharts}
              showTableData={showTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              selectedInicialAge={selectedInicialAge}
              selectedFinalAge={selectedFinalAge}
              selectedPrioridade={selectedPrioridade}
              selectedMeio={selectedMeio}
              selectedRealizacaoTeste={selectedRealizacaoTeste}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsTrackingHpv;
