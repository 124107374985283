import { Dispatch, SetStateAction } from 'react';

export type ErrorField = {
  field: string;
  message: string;
};

interface ClearValuesProps {
  permissao: string;
  setValue: any;
  handleSetFilter: any;
  loadReportsConsolidado: any;
  watch: any;
  setEstadosSelected: any;
  setMunicipiosSelected: any;
  setEstabelecimentosSelected: any;
  showTableData: boolean;
  setTableData: any;
  setShowTableData: any;
  setErrorsFields: Dispatch<SetStateAction<ErrorField[]>>;
  setListFilterToShow: any;
  setDataColetaIni: any;
  setDataColetaFinal: any;
  setDataResultadoFinal: any;
  setDataResultadoIni: any;
  clearNewFields?: () => void;
}

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  loadReportsConsolidado,
  watch,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setTableData,
  setShowTableData,
  setErrorsFields,
  setListFilterToShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
}: ClearValuesProps) => {
  const fieldsToClearWhenIsPadrao: string[] = [
    'uf',
    'municipio',
    'estabelecimentoVinculadoPaciente',

    'atendimento_teste_hpv',
    'resultado_teste_hpv',

    'resultado_citologia',
    'tipos_achados',
    'realizar_biopsia',
    'origem_histopatologia',
    'pre_neoplasico_escamosa',
    'pre_neoplasico_glandular',
    'indicacao_ezt',

    'procedimento',
    'dataInicio',
    'dataFim',
    'dt_coleta',
    'dt_resultado',

    'search'
  ];

  const fieldsToClearWhenIsMunicipal = [
    'estabelecimento_saude',
    ...fieldsToClearWhenIsPadrao,
  ];

  const fieldsToClearWhenEstadual = [
    'municipio',
    ...fieldsToClearWhenIsMunicipal,
  ];

  const fieldsToClearWhenSuperAdmin = [
    'estado',
    ...fieldsToClearWhenEstadual,
  ];

  const clearValues = () => {
    // Reset all form fields
    const fieldsToUse = (() => {
      switch (permissao) {
        case 'isSuperAdmin':
          return fieldsToClearWhenSuperAdmin;
        case 'isEstadual':
          return fieldsToClearWhenEstadual;
        case 'isMunicipal':
          return fieldsToClearWhenIsMunicipal;
        default:
          return fieldsToClearWhenIsPadrao;
      }
    })();

    fieldsToUse.forEach((field) => {
      setValue(field, null);
      handleSetFilter('', field);
    });

    setErrorsFields([]);
    setTableData([]);
    setShowTableData(false);
    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    setListFilterToShow([]);

    // Handle permission-based resets
    if (permissao === 'isMunicipal') {
      setEstabelecimentosSelected([]);
    }
    if (permissao === 'isEstadual') {
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
    }
    if (permissao === 'isSuperAdmin') {
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);
    }
  };

  return { clearValues };
};

export default useClearValues;