import { Button, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import SearchIcon from '@mui/icons-material/Search';

const SearchByNameInput = ({
  control,
  // disableOtherInputs,
  errors,
  handleSetFilter,
}) => {
  return (
    <Controller
      name="search"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            // disabled={disableOtherInputs}
            data-testid="inputSearchByNameCpf"
            label="Pesquisar"
            error={!!errors.search}
            helperText={errors?.search?.message}
            size="small"
            placeholder="Pesquise por Nome, Nº do Cartão SUS ou CPF da paciente"
            sx={{
              '.MuiOutlinedInput-root': { paddingRight: '9px' },
            }}
            value={value ? value : ''}
            onChange={(e) => {
              onChange(e.target.value);
              handleSetFilter(e.target.value, 'search');
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <Button sx={{ padding: 0, minWidth: '24px' }}>
                  <SearchIcon
                    sx={{
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                  />
                </Button>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }}
    />
  );
};

export default SearchByNameInput;
