import { toast } from 'react-toastify';
import { useContext } from 'react';
import { PerfilContext } from '../../../../../context/PerfilContext';

const useVerifyAccessLevel = ({
  setValue,
  getCidadesEstabelecimentos,
  getEstabelecimento,
  currentEstablishment,
  setDisableFilters,
  handleSetFilter,
  setTableData,
  watch,
}) => {
  const { getPermissao } = useContext(PerfilContext);

  const permissao = getPermissao();

  const setDefaultValues = () => {
    setTableData([]);
    return new Promise(async (resolve, reject) => {
      if (currentEstablishment && permissao === 'isPadrao') {
        const payload = {
          estado: currentEstablishment?.endereco?.municipio?.estado.nome,
          municipio: currentEstablishment?.endereco?.municipio.nome,
          estabelecimentoId: currentEstablishment?.id,
          size: watch().size,
          page: watch().page,
        };

        setValue(
          'estado',
          currentEstablishment?.endereco?.municipio?.estado.nome
        );

        await getCidadesEstabelecimentos(
          currentEstablishment?.endereco?.municipio?.estado?.sigla
        );
        setValue('municipio', currentEstablishment?.endereco?.municipio.nome);
        setValue('estabelecimento_saude', currentEstablishment);

        setDisableFilters({
          estado: true,
          municipio: true,
          estabelecimento: true,
        });

        handleSetFilter(
          currentEstablishment?.endereco?.municipio?.nome,
          'municipio'
        );
        handleSetFilter(
          currentEstablishment?.nome_fantasia,
          'estabelecimento_saude'
        );

        handleSetFilter(
          currentEstablishment?.endereco?.municipio?.estado.sigla,
          'estado'
        );

        resolve(payload);
      } else if (currentEstablishment && permissao === 'isMunicipal') {
        const payload = {
          estado: currentEstablishment?.endereco?.municipio?.estado.nome,
          municipio: currentEstablishment?.endereco?.municipio.nome,
          size: watch().size,
          page: watch().page,
        };

        setValue(
          'estado',
          currentEstablishment?.endereco?.municipio?.estado.nome
        );

        await getCidadesEstabelecimentos(
          currentEstablishment?.endereco?.municipio?.estado?.sigla
        );

        setValue('municipio', currentEstablishment?.endereco?.municipio.nome);

        setDisableFilters({
          estado: true,
          municipio: true,
          estabelecimento: false,
        });

        handleSetFilter(
          currentEstablishment?.endereco?.municipio?.estado.sigla,
          'estado'
        );

        handleSetFilter(
          currentEstablishment?.endereco?.municipio?.nome,
          'municipio'
        );

        await getEstabelecimento(
          currentEstablishment?.endereco?.municipio?.nome
        );

        resolve(payload);
      } else if (currentEstablishment && permissao === 'isEstadual') {
        const payload = {
          estado: currentEstablishment?.endereco?.municipio?.estado.nome,
          size: watch().size,
          page: watch().page,
        };

        setValue(
          'estado',
          currentEstablishment?.endereco?.municipio?.estado.nome
        );

        await getCidadesEstabelecimentos(
          currentEstablishment?.endereco?.municipio?.estado?.sigla
        );

        setDisableFilters({
          estado: true,
          municipio: false,
          estabelecimento: false,
        });

        handleSetFilter(
          currentEstablishment?.endereco?.municipio?.estado.sigla,
          'estado'
        );

        resolve(payload);
      } else if (permissao === 'isSuperAdmin') {
        setDisableFilters({
          estado: false,
          municipio: false,
          estabelecimento: false,
        });

        const payload = {
          size: watch().size,
          page: watch().page,
        };

        resolve(payload);
      } else if (!currentEstablishment) {
        resolve('waiting');
      } else {
        reject(
          toast.error('Houve um problema, recarregue a página', {
            position: 'bottom-right',
          })
        );
      }
    });
  };

  return { setDefaultValues, permissao };
};

export default useVerifyAccessLevel;
