import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsCitologiaReflexo } from '../../../../../lib/Reports/getReportsCtiologiaReflexo';

const useRequestFilter = ({ setTableData, setLoadingTable }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsCitologiaReflexo = async ({ payload }) => {
    setLoadingTable(true);
    const response = await getReportsCitologiaReflexo({
      payload,
    });

    setTableData(response);
    setLoadingTable(false);

    setLoading(false);
  };

  return {
    loadReportsCitologiaReflexo,
  };
};

export default useRequestFilter;
