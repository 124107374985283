import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type LoadingContextData = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const FiltersCentralNotificationContext = createContext({} as any);

export const FiltersCentralNotificationProvider = ({ children }) => {
  const initialStateListFilter = [
    { inputName: 'Status', value: '' },

    { inputName: 'Tipo de Notificações', value: '' },

    { inputName: 'Início da Data de Notificação', value: '' },

    { inputName: 'Fim da Data de Notificação', value: '' },

    { inputName: 'Pesquisar', value: '' },
  ];

  const [dataNotificacaoFinal, setDataNotificacaoFinal] = useState<any>();
  const [dataNotificacaoIni, setDataNotificacaoIni] = useState<any>();
  const [periodDtNotificacao, setPeriodDtNotificacao] = useState('period');
  const [selectedOptionsStatus, setSelectedOptionsStatus] = useState<any>([]);
  const [search, setSearch] = useState('');

  const [alertId, setAlertId] = useState(0);

  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);

  const [listFilterShow, setListFilterShow] = useState<any[]>([]);

  const [selectedOptionsNotificationType, setSelectedOptionsNotificationType] =
    useState<any>([]);
  const [selectedOptionsStatusLidas, setSelectedOptionsStatusLidas] =
    useState<any>([
      { label: 'Pendentes', value: 1 },
      { label: 'Resolvidas', value: 2 },
    ]);

  const clearFiltersCentralNotification = () => {
    setSelectedOptionsStatus([]);
    setSelectedOptionsNotificationType([]);

    setDataNotificacaoFinal(null);
    setDataNotificacaoIni(null);

    setListFilter(initialStateListFilter);
    setListFilterShow(initialStateListFilter);
  };

  return (
    <FiltersCentralNotificationContext.Provider
      value={{
        initialStateListFilter,
        dataNotificacaoFinal,
        dataNotificacaoIni,
        periodDtNotificacao,
        selectedOptionsStatus,
        alertId,
        listFilter,
        selectedOptionsNotificationType,
        listFilterShow,
        selectedOptionsStatusLidas,
        search,
        setSearch,
        setDataNotificacaoFinal,
        setDataNotificacaoIni,
        setPeriodDtNotificacao,
        setSelectedOptionsStatus,
        setAlertId,
        setListFilter,
        setListFilterShow,
        setSelectedOptionsNotificationType,
        setSelectedOptionsStatusLidas,
        clearFiltersCentralNotification,
      }}
    >
      {children}
    </FiltersCentralNotificationContext.Provider>
  );
};
