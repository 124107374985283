/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { SideMenu } from '../../components/SideMenu';
import ReturnButton from '../../components/ReturnButton';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import api from '../../api';
import { DisplayFilter, ItemsDisplayFilter } from '../Convocation/styles';
import ChartTotalTests from './Components/ChartTotalTests';
import ChartTotalFaixa from './Components/ChartTotalFaixa';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MultipleFilters from '../../components/MultipleFilters';
import useStatesFields from '../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../lib/auth';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'acs', value: '' },
  { inputName: 'periodo', value: '' },
];
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TrackingHpv = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [categoriesTesteChart, setCategoriesTesteChart] = useState<any>([]);
  const [categoriesFaixa, setCategoriesFaixa] = useState<any>([]);
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [tabSize, setTabSize] = useState('100%');
  const [valueTab, setValueTab] = useState(0);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const [graphicTestData, setGraphicTestData] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const [year, setYear] = useState<any>('');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>();

  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [loadingChart, setLoadingChart] = useState(true);
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const [labelPeriodoGraphics, setLabelPeriodoGraphics] = useState('');

  const [dataFaixa, setDataFaixa] = useState<any>([]);

  const styles = useStyles();

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const optionsPeriodo = [
    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const user = getUserToken();

  const periodosSelectedLabel = graphicTestData?.date_info?.start
    ? !!period
      ? `${moment(period.periodo_inicio).format('DD/MM/YYYY')} a ${moment(
          period.periodo_fim
        ).format('DD/MM/YYYY')}`
      : ''
    : ' Carregando Informações...';

  const updatedAtValue = graphicTestData?.date_info?.updated_at
    ? moment(graphicTestData.date_info.updated_at).format('DD/MM/YYYY - HH:mm')
    : ' Carregando Informações...';

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,
    disableInputEstado,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    setDisableInputEstado,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,

    errorsFields,
    setErrorsFields,
  } = useStatesFields();
  const handleChange = (event, newValue: number) => {
    setValueTab(0);
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  let payload = {
    estados:
      estadosSelected.length > 0 ? estadosSelected.join(', ') : undefined,
    municipios:
      municipiosSelected.length > 0 ? municipiosSelected.join(', ') : undefined,
    estabelecimentos:
      estabelecimentosSelected.length > 0
        ? estabelecimentosSelected.join(', ')
        : undefined,
    profissionais: acsSelected.length > 0 ? acsSelected.join(', ') : undefined,

    periodo_inicio: period?.periodo_inicio ? period.periodo_inicio : undefined,
    periodo_fim: period?.periodo_fim ? period.periodo_fim : undefined,
  };
  const isPadrao = user.permissao_atual.id === 1;
  const isMunicipal = user.permissao_atual.id === 3;
  const isEstadual = user.permissao_atual.id === 4;
  useEffect(() => {
    if (user.permissao_atual.id === 1) {
      setEstabelecimentosSelected([user.estabelecimentoId]);
      payload = {
        ...payload,
        estabelecimentos: user.estabelecimentoId,
      };
    }

    if (isPadrao || isMunicipal || isEstadual) {
      setDisableInputEstado(true);
    }
  }, []);

  const getHpvTeste = async ({ cleared }) => {
    setGraphicTestData([]);
    setCategoriesTesteChart(['']);
    setLoadingChart(true);

    const response = await api.get('/dashboard/rastreamento/hpv/teste', {
      params: cleared ? {} : payload,
    });

    setEstadosSelected(
      response.data.filters_info.estados
        ? [response.data.filters_info.estados]
        : []
    );
    setMunicipiosSelected(
      response.data.filters_info.municipios
        ? [response.data.filters_info.municipios]
        : []
    );

    setGraphicTestData(response.data);

    let arrayCategories: string[] = [];

    for (let category in response.data.data) {
      arrayCategories.push(category);
    }

    setCategoriesTesteChart(arrayCategories);
    setLoadingChart(false);
  };

  const getHpvFaixa = async ({ cleared }) => {
    setDataFaixa([]);
    setCategoriesFaixa(['']);
    const response = await api.get('/dashboard/rastreamento/hpv/faixa', {
      params: cleared ? {} : payload,
    });

    setDataFaixa(response.data);
    let arrayCategories: string[] = [];

    for (let category in response.data.data) {
      arrayCategories.push(category);
    }

    setCategoriesFaixa(arrayCategories);
  };

  const handleCloseModal = () => {
    setPeriod(null);
    handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };
  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const handleClearFilter = () => {
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setAcsSelected([]);

    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
    setEstadosOptions(allEstadosOptions);

    initialStateListFilter.forEach((input) => {
      handleSetFilter(null, input.inputName);
    });

    if (!isPadrao) {
      setEstabelecimentosSelected([]);
    }

    if (!isMunicipal && !isPadrao) {
      setMunicipiosSelected([]);
    }

    if (!isMunicipal && !isPadrao && !isEstadual) {
      setEstadosSelected([]);
    }

    setShowListFilter([]);

    getHpvTeste({ cleared: true });
    getHpvFaixa({ cleared: true });
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      default:
        break;
    }
  };
  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      setValueInputQuadrimestreSemestre('1º Quadrimestre');
    }

    if (valueInputPeriod === 'Semestral') {
      setValueInputQuadrimestreSemestre('1º Semestre');
    }
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-01`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getHpvTeste({ cleared: false });
    getHpvFaixa({ cleared: false });
  }, []);

  useEffect(() => {
    if (year?.length === 4) {
      setPeriod({
        periodo_inicio: year + initialDayAndMonth,
        periodo_fim: year + endDayAndMonth,
      });
    }
  }, [year]);

  useEffect(() => {
    if (period) {
      handleSetFilter(
        `Período Inicial: ${moment(period?.periodo_inicio).format(
          'DD/MM/YYYY'
        )} - Período Final: ${moment(period?.periodo_fim).format(
          'DD/MM/YYYY'
        )}`,
        'periodo'
      );
    } else {
      handleSetFilter(null, 'periodo');
    }
  }, [period]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setTabSize('100%');
    } else {
      setTabSize('100%');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <SideMenu>
      <Container sx={{ marginTop: '64px' }}>
        <Dialog
          open={openModalPeriodo}
          onClose={() => setOpenModalPeriodo(false)}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight="bold">
              Tipo de Período
            </Typography>
            <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
              <Paper
                variant="outlined"
                sx={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Selecione o Período para Visualização dos Dados
                </Typography>
                <Autocomplete
                  placeholder="Tipo de Período"
                  value={valuePeriodoModal}
                  onChange={(_, newValue: any) => {
                    handleSelectPeriodModal(newValue);
                  }}
                  options={optionsPeriodo}
                  getOptionLabel={(option) => option.label ?? ''}
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      fullWidth
                      label="Tipo de Período"
                      variant="outlined"
                    />
                  )}
                />
                {valueInputPeriod === 'Últimos 12 meses' ||
                !valueInputPeriod ? undefined : (
                  <Autocomplete
                    options={['2024', '2023', '2022']}
                    onChange={(_, newValue) => {
                      handleChangeWhenYearSelected(newValue);
                    }}
                    value={year ? year : ''}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione o Ano" />
                    )}
                  />
                )}
                {}

                {(valueInputPeriod === 'Quadrimestral' ||
                  valueInputPeriod === 'Semestral') && (
                  <Autocomplete
                    options={
                      valueInputPeriod === 'Quadrimestral'
                        ? optionsQuadrimestre
                        : optionsSemestre
                    }
                    value={
                      valueInputQuadrimestreSemestre
                        ? valueInputQuadrimestreSemestre
                        : ''
                    }
                    onChange={(_, newValue) => {
                      handleChangeSemestreOrQuadrimestre(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          valueInputPeriod === 'Quadrimestral'
                            ? 'Selecione o Quadrimestre'
                            : 'Selecione o Semestre'
                        }
                      />
                    )}
                  />
                )}
              </Paper>
            </DialogContent>
          </DialogTitle>

          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleCloseModal();
              }}
              startIcon={<ClearIcon />}
            >
              CANCELAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModalPeriodo(false)}
              endIcon={<CheckIcon />}
            >
              CONFIRMAR
            </Button>
          </DialogActions>
        </Dialog>
        <Box>
          <ReturnButton backPage="/home" />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                data-testid="panelTrackingHpvTitle"
              >
                Painel de Rastreamento para Testes de HPV
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography
                  key="3"
                  color="text.primary"
                  data-testid="trackingHpvBreadCrumb"
                >
                  Painel de Rastreamento para Testes de HPV
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Box mt={3} sx={{ width: '100%' }}>
            <Tabs
              orientation={
                windowDimensions.width < 768 ? 'vertical' : 'horizontal'
              }
              variant="scrollable"
              sx={{
                '& button': {
                  maxWidth: `${windowDimensions.width < 768 ? '100%' : '50%'}`,
                },
              }}
              value={valueTab}
              onChange={handleChange}
              className={styles.tabContainer}
            >
              <Tab
                sx={{ width: tabSize }}
                label="RASTREAMENTO PARA TESTES DE HPV"
                {...a11yProps(0)}
                tabIndex={0}
              />
              <Tab
                sx={{ width: tabSize }}
                disabled
                label="ACOMPANHAMENTO"
                {...a11yProps(1)}
                tabIndex={1}
              />
            </Tabs>

            <TabPanel value={valueTab} index={0}>
              <Box sx={{ marginTop: '24px' }}>
                {/**O componente abaixo foi construído para que os dados dos gráficos
                 * possam ser localizados nos testes automatizados do Cypress
                 */}
                <div style={{ display: 'none' }}>
                  <ul>
                    {dataFaixa?.data &&
                      Object.keys(dataFaixa?.data).map((label) => {
                        const currentValue = dataFaixa?.data[label];

                        const percent2534 = Number(
                          (currentValue.faixas['25_34'] / currentValue.total) *
                            100
                        );
                        const percent3545 = Number(
                          (currentValue.faixas['35_45'] / currentValue.total) *
                            100
                        );

                        const percent4664 = Number(
                          (currentValue.faixas['46_64'] / currentValue.total) *
                            100
                        );

                        return (
                          <li>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelSerieChartFaixaEtaria"
                            >
                              {label}
                            </p>
                            <br />
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelTotalChartFaixaEtaria"
                            >
                              Total:{' '}
                              <span data-testid="totalValueItemLabelChartFaixaEtaria">
                                {currentValue.total}
                              </span>
                            </p>

                            <p
                              style={{ margin: 0 }}
                              data-testid="labelTwentyFiveToThirtyFourYears"
                            >
                              25 a 34 anos{' '}
                              <span className="quantityOffWomansWithTwentyFiveToThirtyFourYears">
                                {Math.round(percent2534)}%
                              </span>
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelThirtyFiveToFortyFiveYears"
                            >
                              35 a 45 anos{' '}
                              <span className="quantityOffWomansWithThirtyFiveToFortyFiveYears">
                                {Math.round(percent3545)}%
                              </span>
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelFortySixToSixtyFourYears"
                            >
                              46 a 64 anos{' '}
                              <span className="quantityOffWomansWithFortySixToSixtyFourYears">
                                {Math.round(percent4664)}%
                              </span>
                            </p>
                          </li>
                        );
                      })}
                  </ul>

                  <ul>
                    {graphicTestData?.data &&
                      Object.keys(graphicTestData?.data).map((label) => {
                        const currentValue = graphicTestData?.data[label];
                        const { has_hpv, need_hpv, other_hpv } = currentValue;
                        const total = has_hpv + need_hpv + other_hpv;

                        return (
                          <li>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelSerieChartTotalWomanEligible"
                            >
                              {label}
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="valueTotalWomanEligible"
                            >
                              Total: {total}
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelWomanIdentifiedAndNeedHpv"
                            >
                              Mulheres elegíveis identificadas no banco de dados
                              <br /> que não realizaram o teste de hpv:{' '}
                              <span data-testid="valueWomanIdentifiedAndNeedHpv">
                                {need_hpv}
                              </span>
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelWomanIdentifiedAndHasHpv"
                            >
                              Mulheres elegíveis identificadas no banco de dados
                              <br /> que já realizaram o teste de hpv:{' '}
                              <span data-testid="valueWomanIdentifiedAndHasHpv">
                                {has_hpv}
                              </span>
                            </p>
                            <p
                              style={{ margin: 0 }}
                              data-testid="labelWomanNotIdentifiedAndHasHpv"
                            >
                              Mulheres elegíveis não identificadas no banco de
                              dados
                              <br /> que já realizaram o teste de hpv:{' '}
                              <span data-testid="valueWomanNotIdentifiedAndHasHpv">
                                {other_hpv}
                              </span>
                            </p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <Grid container spacing={3} justifyContent="flex-end">
                  <MultipleFilters
                    requiredMunicipio={
                      estadosSelected.length > 1 ? false : true
                    }
                    disableInputEstado={disableInputEstado}
                    handleSetFilter={handleSetFilter}
                    mdEstado={2.4}
                    mdMunicipio={2.4}
                    mdEstabelecimento={2.4}
                    mdEquipe={2.4}
                    // mdAcs={2.4}
                    fieldEquipe={false}
                    fieldAcs={true}
                    acsSelected={acsSelected}
                    arrayAcs={arrayAcs}
                    searchAcs={searchAcs}
                    allAcsOptions={allAcsOptions}
                    setAllAcsOptions={setAllAcsOptions}
                    anchorElAcs={anchorElAcs}
                    disableAcsInput={disableAcsInput}
                    setAnchorElAcs={setAnchorElAcs}
                    setArrayAcs={setArrayAcs}
                    setSearchAcs={setArrayAcs}
                    setAcsSelected={setAcsSelected}
                    anchorElEstados={anchorElEstados}
                    searchEstado={searchEstado}
                    estadosOptions={estadosOptions}
                    allEstadosOptions={allEstadosOptions}
                    estadosSelected={estadosSelected}
                    setAnchorElEstados={setAnchorElEstados}
                    setSearchEstado={setSearchEstado}
                    setEstadosOptions={setEstadosOptions}
                    setAllEstadosOptions={setAllEstadosOptions}
                    setEstadosSelected={setEstadosSelected}
                    allMunicipiosOptions={allMunicipiosOptions}
                    searchMunicipio={searchMunicipio}
                    anchorElMunicipios={anchorElMunicipios}
                    municipiosSelected={municipiosSelected}
                    optionsMunicipios={optionsMunicipios}
                    disableMunicipioInput={disableMunicipioInput}
                    setAllMunicipiosOptions={setAllMunicipiosOptions}
                    setSearchMunicipio={setSearchMunicipio}
                    setAnchorElMunicipios={setAnchorElMunicipios}
                    setMunicipiosSelected={setMunicipiosSelected}
                    setOptionsMunicipios={setOptionsMunicipios}
                    optionsEstabelecimentos={optionsEstabelecimentos}
                    allEstabelecimentosOptions={allEstabelecimentosOptions}
                    searchEstabelecimentos={searchEstabelecimentos}
                    anchorElEstabelecimentos={anchorElEstabelecimentos}
                    estabelecimentosSelected={estabelecimentosSelected}
                    disableEstabelecimentoInput={disableEstabelecimentoInput}
                    setOptionsEstabelecimentos={setOptionsEstabelecimentos}
                    setAllEstabelecimentosOptions={
                      setAllEstabelecimentosOptions
                    }
                    setSearchEstabelecimentos={setSearchEstabelecimentos}
                    setAnchorEstabelecimentos={setAnchorEstabelecimentos}
                    setEstabelecimentosSelected={setEstabelecimentosSelected}
                    allEquipesOptions={allEquipesOptions}
                    searchEquipes={searchEquipes}
                    anchorElEquipe={anchorElEquipe}
                    arrayEquipes={arrayEquipes}
                    equipesSelected={equipesSelected}
                    disableEquipeInput={disableEquipeInput}
                    setAllEquipesOptions={setAllEquipesOptions}
                    setSearchEquipes={setSearchEquipes}
                    setAnchorElEquipe={setAnchorElEquipe}
                    setArrayEquipes={setArrayEquipes}
                    setEquipesSelected={setEquipesSelected}
                    setDisabledMunicipioInput={setDisabledMunicipioInput}
                    setDisabledEstabelecimentoInput={
                      setDisabledEstabelecimentoInput
                    }
                    setDisableEquipeInput={setDisableEquipeInput}
                    setDisableAcsInput={setDisableAcsInput}
                    errorsFields={errorsFields}
                    setErrorsFields={setErrorsFields}
                  />

                  <Grid item xs={12} md={2.4}>
                    <StyledAsteriskTextField
                      fullWidth
                      data-testid="periodoInput"
                      size="small"
                      label="Período"
                      value={valueInputPeriod ? valueInputPeriod : ''}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => setOpenModalPeriodo(true)}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      data-testid="submitFilterPanelTrackingHpv"
                      variant="contained"
                      onClick={() => {
                        getHpvTeste({ cleared: false });
                        getHpvFaixa({ cleared: false });
                        setShowListFilter(listFilter);
                        setLabelPeriodoGraphics(valuePeriodoModal?.label);
                      }}
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      data-testid="clearFilterPanelTrackingHpv"
                      sx={{
                        color: '#f44b3e',
                        fontWeight: 'bold',
                        borderColor: '#f7a7a1',
                        backgroundColor: '#fafafa',
                        '&:hover': {
                          backgroundColor: '#fafafa',
                          color: '#f44b3e',
                          borderColor: '#f7a7a1',
                        },
                      }}
                      variant="outlined"
                      onClick={() => {
                        handleClearFilter();
                      }}
                    >
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={valueTab} index={0}>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ marginTop: '24px' }}
              >
                Painéis
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginTop: '8px' }}
              >
                Período:
                {periodosSelectedLabel}
              </Typography>
              {/* {user.permissao_atual.id === 2 && (
                <p style={{ marginTop: '8px' }}>
                  Data de atualização: {updatedAtValue}
                </p>
              )} */}

              <DisplayFilter
                style={{ marginTop: '24px', marginBottom: '24px' }}
              >
                <p style={{ margin: 0, width: '104px' }}>Filtro ativos:</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '10px',
                    flexWrap: 'wrap',
                  }}
                >
                  {showListFilter.map((item) => (
                    <>
                      {item.value ? (
                        <>
                          <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                        </>
                      ) : undefined}
                    </>
                  ))}
                </div>
              </DisplayFilter>

              <ChartTotalTests
                categoriesTesteChart={categoriesTesteChart}
                dataEstados={graphicTestData?.data}
                subtitleGraphic={labelPeriodoGraphics}
                loadingChart={loadingChart}
                payload={payload}
              />

              <ChartTotalFaixa
                payload={payload}
                loadingChart={loadingChart}
                categoriesFaixa={categoriesFaixa}
                data={dataFaixa?.data}
                subtitleGraphic={labelPeriodoGraphics}
              />
            </TabPanel>
          </Box>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default TrackingHpv;
