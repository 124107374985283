import { TableCell, TableRow, Typography } from '@mui/material';
import { formatCpf } from '../../../../utils/formatters';
import moment from 'moment';
import { useContext } from 'react';
import { PerfilContext } from '../../../../context/PerfilContext';
import CustomizedButton from '../../../../components/CustomizedButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';

const StatusEnum = {
  Agendada: 0,
  Pendente: 1,
  NaoAtendida: 2,
  NaoElegivel: 3,
};
enum ModalType {
  Agendada = 0,
  Pendente = 1,
  NaoAtendida = 2,
  NaoElegivel = 3,
}

const RowsTableConvocation = ({
  tableData,
  setModalAberto,
  setRegistroSelecionado,
}) => {
  const { hasPermission } = useContext(PerfilContext);
  const getColorAndLabelStatus = (value) => {
    let color;
    let label;
    switch (value) {
      case StatusEnum.Agendada:
        color = '#4CAF50';
        label = 'Agendada';
        break;
      case StatusEnum.Pendente:
        color = '#FFB547';
        label = 'Pendentes';
        break;
      case StatusEnum.NaoAtendida:
        color = '#FC5555';
        label = 'Não Atendidas';
        break;
      case StatusEnum.NaoElegivel:
        color = '#7986CB';
        label = 'Não Elegível';
        break;
      default:
        break;
    }

    return { color, label };
  };

  const abrirModal = (status, registro) => {
    const modalTypes = Object.values(ModalType);

    if (modalTypes.includes(status)) {
      const modalType = ModalType[status];
      setModalAberto(modalType);
      setRegistroSelecionado(registro);
    } else {
    }
  };

  return (
    <>
      {tableData?.rows?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row.pessoa.estabelecimento.nome_fantasia).toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell align="left" sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body2" fontSize="14px">
                {String(
                  row?.pessoa.endereco
                    ? `${row?.pessoa.endereco.logradouro}, ${
                        row?.pessoa.endereco.numero
                      }${
                        row?.pessoa.endereco.complemento
                          ? `, ${row?.pessoa.endereco.complemento}`
                          : ''
                      } `
                    : ''
                ).toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {String(row?.profissional?.pessoa?.nome ?? '').toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell align="left" sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body2" fontSize="14px">
                <p style={{ margin: 0 }}>{row?.tipo_convocacao}º Convocação</p>
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {moment(row?.convocado_em).format('DD/MM/YYYY')}
              </Typography>
            </TableCell>

            <TableCell sx={{ width: '162px' }}>
              <Typography color="#000001de" variant="body1" fontSize="14px">
                {moment(row?.agendado_em).format('DD/MM/YYYY')}
              </Typography>
            </TableCell>

            <TableCell
              align="center"
              sx={{
                boxShadow: '-3px 0px 5px -1px rgba(0, 0, 0, 0.20)',
                width: '162px',
                padding: row?.status === StatusEnum.NaoAtendida ? 0 : '16px',
              }}
            >
              <Typography
                color="white"
                variant="body2"
                sx={{
                  backgroundColor: getColorAndLabelStatus(row?.status).color,
                  borderRadius: '16px',
                  textAlign: 'center',
                  padding: '3px 6px',
                  marginLeft: '14px',
                  // padding: '3px 15px',
                  // minWidth: '83px',
                  width: 'fit-content',
                }}
                fontSize="13px"
              >
                {getColorAndLabelStatus(row?.status).label}
              </Typography>
            </TableCell>
            {/* {((hasPermission('CREATE_CONVOCATORIA_BUSCA_ATIVA') &&
              row?.status === StatusEnum.Pendente) ||
              row?.status !== StatusEnum.Pendente) && (
              <TableCell>
                <CustomizedButton
                  variant="outlined"
                  onClick={() => abrirModal(row?.status, row)}
                  startIcon={
                    row?.status === StatusEnum.NaoElegivel ||
                    row?.status === StatusEnum.Agendada ||
                    row?.status === StatusEnum.NaoAtendida ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  BUSCA ATIVA
                </CustomizedButton>
              </TableCell>
            )} */}
          </TableRow>
        );
      })}
    </>
  );
};

export default RowsTableConvocation;
