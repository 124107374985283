import { useState } from 'react';

const useStatesFields = () => {
  const [optionsServicos, setOptionsServicos] = useState<any>([]);
  const [allServicosOptions, setAllServicosOptions] = useState<any>([]);

  const [anchorElEstados, setAnchorElEstados] = useState<any>(null);
  const [searchEstado, setSearchEstado] = useState('');
  const [estadosOptions, setEstadosOptions] = useState<any>([]);
  const [allEstadosOptions, setAllEstadosOptions] = useState<any>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [disableInputEstado, setDisableInputEstado] = useState(false);
  const [loadingEstados, setLoadingEstados] = useState(true);

  const [allMunicipiosOptions, setAllMunicipiosOptions] = useState<any>([]);
  const [searchMunicipio, setSearchMunicipio] = useState('');
  const [anchorElMunicipios, setAnchorElMunicipios] = useState<any>(null);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [optionsMunicipios, setOptionsMunicipios] = useState<any>();
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);

  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );
  const [optionsProfissionaisLaboratorio, setOptionsProfisisonaisLaboratorio] =
    useState<any>([]);
  const [allEstabelecimentosOptions, setAllEstabelecimentosOptions] =
    useState<any>([]);
  const [searchEstabelecimentos, setSearchEstabelecimentos] = useState('');
  const [anchorElEstabelecimentos, setAnchorEstabelecimentos] =
    useState<any>(null);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(false);

  const [allEquipesOptions, setAllEquipesOptions] = useState<any>([]);
  const [searchEquipes, setSearchEquipes] = useState('');
  const [anchorElEquipe, setAnchorElEquipe] = useState<any>(null);
  const [arrayEquipes, setArrayEquipes] = useState<any>([]);
  const [equipesSelected, setEquipesSelected] = useState<any[]>([]);
  const [loadingEquipes, setLoadingEquipes] = useState(false);

  const [allAcsOptions, setAllAcsOptions] = useState<any>([]);
  const [searchAcs, setSearchAcs] = useState('');
  const [anchorElAcs, setAnchorElAcs] = useState<any>(null);
  const [arrayAcs, setArrayAcs] = useState<any>([]);
  const [acsSelected, setAcsSelected] = useState<any[]>([]);
  const [loadingAcs, setLoadingAcs] = useState(false);
  const [loadingLaboratorio, setLoadingLaboratorio] = useState(false);

  const [disableMunicipioInput, setDisabledMunicipioInput] = useState(false);
  const [disableEquipeInput, setDisableEquipeInput] = useState(true);
  const [disableAcsInput, setDisableAcsInput] = useState(true);
  const [disableEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(false);

  const [errorsFields, setErrorsFields] = useState<any>([]);

  const [optionsLaboratorios, setOptionsLaboratorios] = useState<any>([]);
  const [allLaboratoriosOptions, setAllLaboratoriosOptions] = useState<any>([]);
  const [searchLaboratorios, setSearchLaboratorios] = useState<any>([]);
  const [anchorElLaboratorios, setAnchorLaboratorios] = useState<any>([]);
  const [laboratoriosSelected, setLaboratoriosSelected] = useState<any>([]);

  return {
    optionsProfissionaisLaboratorio,
    setOptionsProfisisonaisLaboratorio,
    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,
    estadosSelected,
    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    municipiosSelected,
    optionsMunicipios,
    loadingMunicipios,
    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    estabelecimentosSelected,
    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    allAcsOptions,
    searchAcs,
    anchorElAcs,
    arrayAcs,
    acsSelected,
    disableMunicipioInput,
    disableEquipeInput,
    disableAcsInput,
    disableEstabelecimentoInput,
    loadingEstabelecimentos,
    loadingEquipes,
    loadingAcs,
    errorsFields,
    disableInputEstado,

    allLaboratoriosOptions,
    searchLaboratorios,
    anchorElLaboratorios,
    optionsLaboratorios,
    laboratoriosSelected,
    setLaboratoriosSelected,

    setOptionsLaboratorios,
    setAllLaboratoriosOptions,
    setSearchLaboratorios,
    setAnchorLaboratorios,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    setEstadosSelected,
    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setMunicipiosSelected,
    setOptionsMunicipios,
    setLoadingMunicipios,
    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setEstabelecimentosSelected,
    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setAllAcsOptions,
    setSearchAcs,
    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setDisabledMunicipioInput,
    setDisableEquipeInput,
    setDisableAcsInput,
    setDisabledEstabelecimentoInput,
    setLoadingEstabelecimentos,
    setLoadingEquipes,
    setErrorsFields,
    setLoadingAcs,
    setDisableInputEstado,

    optionsServicos,
    setOptionsServicos,
    allServicosOptions,
    setAllServicosOptions,
    loadingEstados,
    setLoadingEstados,
    loadingLaboratorio,
    setLoadingLaboratorio,
  };
};

export default useStatesFields;
