import { useContext, useEffect } from 'react';
import { PerfilContext } from '../../context/PerfilContext';

const useActionsControlInputs = ({
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,

  setDisabledEstadoInput,
  setDisabledMunicipioInput,
  setDisabledEstabelecimentoInput,

  handleSetFilter,

  setValue,
}) => {
  const loadMunicipiosOptions = async () => {};
  const {
    isSuperAdmin,
    isEstadual,
    isMunicipal,
    isAdminLaboratorio,
    isPadraoEstabelecimento,
    isMedicoLaboratorio,
    isOutrosLaboratorio,
    estabelecimentoIdUser,
    estabelecimentoNameUser,
    municipioUserId,
    municipioUserName,
    estadoUserSigla,
    userName,
    userCpf,
  } = useContext(PerfilContext);

  const structureOffActions = ({
    arrSelected,
    listOffSetStatesThatDisabled,
    listOffInputsNamesThatCleared,
    fnThatPopuledOptionsNextInput,
  }) => {
    if (arrSelected.length === 0) {
      listOffSetStatesThatDisabled.forEach((setState) => {
        setState(true);
      });

      listOffInputsNamesThatCleared.forEach((inputName) => {
        handleSetFilter('', inputName);
        setValue(inputName, []);
      });
    }

    if (arrSelected.length === 1) {
      fnThatPopuledOptionsNextInput();
      listOffSetStatesThatDisabled.length > 0 &&
        listOffSetStatesThatDisabled[0](false);
    }

    if (arrSelected.length > 1) {
      listOffSetStatesThatDisabled[0](true);
    }
  };

  const actionsEstadoSelected = async () => {
    if (isSuperAdmin) {
      structureOffActions({
        arrSelected: estadosSelected,
        listOffSetStatesThatDisabled: [
          setDisabledMunicipioInput,
          setDisabledEstabelecimentoInput,
        ],
        listOffInputsNamesThatCleared: ['municipio', 'estabelecimento'],
        fnThatPopuledOptionsNextInput: loadMunicipiosOptions,
      });
    }
  };
  const actionsMunicipioSelected = async () => {
    if (!isMunicipal && !isPadraoEstabelecimento) {
      structureOffActions({
        arrSelected: municipiosSelected,
        listOffSetStatesThatDisabled: [setDisabledEstabelecimentoInput],
        listOffInputsNamesThatCleared: ['estabelecimento'],
        fnThatPopuledOptionsNextInput: loadMunicipiosOptions,
      });
    }
  };
  const actionsEstabelecimentoSelected = async () => {
    if (!isPadraoEstabelecimento) {
      structureOffActions({
        arrSelected: estabelecimentosSelected,
        listOffSetStatesThatDisabled: [],
        listOffInputsNamesThatCleared: [''],
        fnThatPopuledOptionsNextInput: () => {
          return;
        },
      });
    }
  };

  return {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  };
};

export default useActionsControlInputs;
