/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import { ContainerEstablishment, DropdownButton } from './style';
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  Checkbox,
} from '@mui/material';

import Typography from '@mui/material/Typography';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { InformationPaperStateContext } from '../../context/InformationPaperStateContext';
import { GetPerfis } from '../../lib/perfis';
import { EstablishmentContext } from '../../context/EstablishmentContext';
import { refreshLogin } from '../../lib/auth';
import { LoadingContext } from '../../context/LoadingContext';

const SelectEstablishment = () => {
  const [statePoppers, setStatePoppers] = useState<any>({
    firstPopper: null,
    secondPopper: null,
    thirdPopper: null,
    selectedEstado: null,
    selectedMunicipio: null,
  });

  const [indexSelectedItem, setIndexSelectedItem] = useState({
    firstPopper: null,
    secondPopper: null,
    thirdPopper: null,
  });

  const [innerWidth, setInnerWidth] = useState<number>(0);

  const {
    openInformationPaper,
    setOpenInformationPaper,
    dontShowAgainCheckbox,
    setDontShowAgainCheckbox,
  } = useContext(InformationPaperStateContext);

  const {
    currentEstablishment,
    setEstablishments,
    establishments,
    setCurrentEstablishment,
  } = useContext(EstablishmentContext);

  const [establishmentsList, setEstablishmentsList] = useState<any>();

  const buttonEstablishmentsRef = useRef(null);
  const firstPopperRef = useRef(null);
  const secondPopperRef = useRef(null);

  const handleOpenFirstPopper = (event) => {
    const element1: any = buttonEstablishmentsRef.current;
    if (statePoppers.firstPopper) {
      setStatePoppers({
        firstPopper: null,
        secondPopper: null,
        thirdPopper: null,
        selectedEstado: null,
        selectedMunicipio: null,
      });

      return;
    }
    if (element1) {
      const propertiesSizeButton = element1.getBoundingClientRect();
      setStatePoppers((prev) => {
        return {
          ...prev,
          firstPopper: {
            top: propertiesSizeButton.bottom,
            width: propertiesSizeButton.width,
            left: propertiesSizeButton.left,
          },
        };
      });
    }
  };

  const handleSecondPopper = (index, estadoNome) => {
    setIndexSelectedItem({ ...indexSelectedItem, firstPopper: index });
    const statePopperElement: any = firstPopperRef.current;
    if (statePopperElement) {
      const propertiesStatePopper = statePopperElement.getBoundingClientRect();
      setStatePoppers((prev) => {
        const leftSecondPopper =
          statePoppers.firstPopper.left + statePoppers.firstPopper.width + 10;
        return {
          ...prev,
          firstPopper: {
            ...statePoppers.firstPopper,
            height: propertiesStatePopper.height,
          },
          secondPopper: {
            top: propertiesStatePopper.bottom,
            left: leftSecondPopper,
          },
          selectedEstado: estadoNome,
        };
      });
    }
  };

  const handleThirdPopper = (index, municipioNome) => {
    setIndexSelectedItem({ ...indexSelectedItem, secondPopper: index });

    const secondPopperElement: any = secondPopperRef.current;

    if (secondPopperElement) {
      const propertiesSecondPopper =
        secondPopperElement.getBoundingClientRect();

      setStatePoppers((prev) => {
        const leftThirdPopper =
          statePoppers.firstPopper.left +
          statePoppers.firstPopper.width +
          propertiesSecondPopper.width +
          20;
        return {
          ...prev,
          secondPopper: {
            ...statePoppers.secondPopper,
            height: propertiesSecondPopper.height,
          },
          thirdPopper: {
            top: propertiesSecondPopper.bottom,
            width: propertiesSecondPopper.width,
            left: leftThirdPopper,
          },
          selectedMunicipio: municipioNome,
        };
      });
    }
  };
  const responsivePropertiesOffThirdPopperDesktop = () => {
    if (innerWidth > 768 && innerWidth < 1200) {
      return {
        backgroundColor: 'white',
        color: 'black',
        position: 'absolute',
        top: `calc(${
          statePoppers.firstPopper.top + statePoppers.secondPopper.height
        }px + 20px)`,
        left: `${statePoppers.secondPopper.left}px`,
        padding: '10px 20px',
      };
    } else if (innerWidth > 1200) {
      return {
        backgroundColor: 'white',
        color: 'black',
        position: 'absolute',
        top: `calc(${statePoppers.firstPopper.top}px + 10px)`,
        left: `${statePoppers.thirdPopper.left}px`,
        padding: '10px 20px',
      };
    }
  };

  const handleCloseInformationPaper = () => {
    if (dontShowAgainCheckbox) {
      localStorage.setItem('dontShowInformationEstablishmentPaper', 'true');
    }
    setOpenInformationPaper(false);
  };

  const getEstablishments = async () => {
    try {
      const perfis = await GetPerfis();

      if (!localStorage.getItem('id_last_establishment')) {
        localStorage.setItem(
          'id_last_establishment',
          String(perfis[0].perfis[0].estabelecimento_saude.id)
        );
      }

      const currentEstablishmentId = localStorage.getItem(
        'id_last_establishment'
      );

      setEstablishments(perfis[0].perfis);

      const defaultEstablishment = perfis[0].perfis.find(
        (item) =>
          item.estabelecimento_saude.id === Number(currentEstablishmentId)
      ).estabelecimento_saude;

      setCurrentEstablishment({
        name: defaultEstablishment?.nome_fantasia,
        cnes: defaultEstablishment?.cnes,
        city: defaultEstablishment?.endereco.municipio.nome,
        uf: defaultEstablishment?.endereco.municipio.estado.sigla,
        estabelecimentoId: defaultEstablishment?.id,
        endereco: defaultEstablishment.endereco,
        ...defaultEstablishment,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [lastSstateNameSelected, setLastStateNameSelected] = useState('');

  const closePopperWhenClickSameOption = (clickedStateName) => {
    if (clickedStateName === lastSstateNameSelected) {
      setStatePoppers({
        firstPopper: null,
        secondPopper: null,
        thirdPopper: null,
        selectedMunicipio: null,
      });
    }
  };

  useEffect(() => {
    const arrayEstablishments = establishments;

    // Objeto final que será construído
    const objectToPopuleComponent: any = [];

    // Função para encontrar o índice do estado no array resultado
    function findEstadoIndex(estadoNome) {
      return objectToPopuleComponent.findIndex(
        (estado) => estado.estadoNome === estadoNome
      );
    }

    // Função para encontrar o índice do município no array resultado
    function findMunicipioIndex(estadoIndex, municipioNome) {
      return objectToPopuleComponent[estadoIndex].municipios.findIndex(
        (municipio) => municipio.municipioNome === municipioNome
      );
    }

    // Percorre os dados e monta o objeto que irá popular os componentes
    arrayEstablishments.forEach((item) => {
      const estadoNome =
        item.estabelecimento_saude.endereco.municipio.estado.nome;
      const municipioNome = item.estabelecimento_saude.endereco.municipio.nome;
      const estabelecimentoNome = item.estabelecimento_saude.nome_fantasia;
      const id = item.estabelecimento_saude.id;

      const estadoIndex = findEstadoIndex(estadoNome);

      if (estadoIndex === -1) {
        // Estado ainda não existe no array que irá popular os componentes
        objectToPopuleComponent.push({
          estadoNome,
          municipios: [
            {
              municipioNome,
              estabelecimentos: [
                {
                  estabelecimentoNome,
                  id,
                },
              ],
            },
          ],
        });
      } else {
        const municipioIndex = findMunicipioIndex(estadoIndex, municipioNome);

        if (municipioIndex === -1) {
          // Município ainda não existe no array que irá popular os componentes
          objectToPopuleComponent[estadoIndex].municipios.push({
            municipioNome,
            estabelecimentos: [
              {
                estabelecimentoNome,
                id,
              },
            ],
          });
        } else {
          // Município já existe, apenas adiciona o estabelecimento
          objectToPopuleComponent[estadoIndex].municipios[
            municipioIndex
          ].estabelecimentos.push({
            estabelecimentoNome,
            id,
          });
        }
      }
    });

    setEstablishmentsList(objectToPopuleComponent);
  }, [establishments]);

  const selectedEstadoData = establishmentsList?.find(
    (estado) => estado.estadoNome === statePoppers.selectedEstado
  );

  const selectedMunicipioData = selectedEstadoData?.municipios?.find(
    (municipio) => municipio.municipioNome === statePoppers.selectedMunicipio
  );

  const { setLoading } = useContext(LoadingContext);

  const onSelectEstablishment = (id) => {
    setLoading(true);
    const dataLogin = JSON.parse(
      atob(String(localStorage.getItem('bG9naW4=')))
    );
    localStorage.setItem('id_last_establishment', String(id));
    refreshLogin(dataLogin.cpf, dataLogin.password, id)
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        // setError(err.message);
        return err.message;
      });
  };

  useEffect(() => {
    getEstablishments();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, [innerWidth]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ContainerEstablishment
        ref={buttonEstablishmentsRef}
        sx={{
          outline: statePoppers.firstPopper ? '1.5px solid #fafafa' : '',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'normal',
              marginBottom: '8px',
            }}
          >
            {currentEstablishment?.name ? currentEstablishment?.name : ''}
          </Typography>
          <Typography sx={{ fontWeight: 'lighter', fontSize: '14px' }}>
            CNES {currentEstablishment?.cnes ? currentEstablishment?.cnes : ''}{' '}
            -{' '}
            {currentEstablishment?.city
              ? `${currentEstablishment?.city} / ${currentEstablishment?.uf}`
              : ''}
          </Typography>
        </div>
        <DropdownButton onClick={handleOpenFirstPopper} aria-describedby={'id'}>
          {statePoppers.firstPopper ? (
            <KeyboardArrowDownIcon sx={{ color: 'white' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ color: 'white' }} />
          )}
        </DropdownButton>

        {innerWidth > 768 ? (
          <>
            {statePoppers.firstPopper && (
              <Paper
                ref={firstPopperRef}
                elevation={3}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  position: 'absolute',
                  width: `${statePoppers.firstPopper.width}px`,
                  top: `calc(${statePoppers.firstPopper.top}px + 10px)`,
                  left: `${statePoppers.firstPopper.left}px`,
                  padding: '10px 20px',
                }}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Estado do Ambiente de Trabalho
                  <List>
                    {establishmentsList.map((estado) => (
                      <ListItemButton
                        key={estado.estadoNome}
                        onClick={(index) => {
                          setLastStateNameSelected(estado.estadoNome);
                          closePopperWhenClickSameOption(estado.estadoNome);
                          handleSecondPopper(index, estado.estadoNome);
                        }}
                        sx={{ padding: '0px' }}
                      >
                        <ListItemText>{estado.estadoNome}</ListItemText>
                        <ListItemIcon sx={{ minWidth: '0px' }}>
                          <KeyboardArrowRightIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                  </List>
                </h2>
              </Paper>
            )}

            {statePoppers.secondPopper && selectedEstadoData && (
              <Paper
                ref={secondPopperRef}
                elevation={3}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  position: 'absolute',
                  top: `calc(${statePoppers.firstPopper.top}px + 10px)`,
                  left: `${statePoppers.secondPopper.left}px`,
                  padding: '10px 20px',
                }}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Município do Ambiente de Trabalho
                  <List>
                    {selectedEstadoData.municipios.map((municipio) => (
                      <ListItemButton
                        key={municipio.municipioNome}
                        onClick={(index) =>
                          handleThirdPopper(index, municipio.municipioNome)
                        }
                        sx={{ padding: '0px' }}
                      >
                        <ListItemText>{municipio.municipioNome}</ListItemText>
                        <ListItemIcon sx={{ minWidth: '0px' }}>
                          <KeyboardArrowRightIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                  </List>
                </h2>
              </Paper>
            )}

            {statePoppers.thirdPopper && selectedMunicipioData && (
              <Paper
                elevation={3}
                sx={responsivePropertiesOffThirdPopperDesktop()}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Selecione o Ambiente de Trabalho
                  <List>
                    {selectedMunicipioData?.estabelecimentos.map(
                      (estabelecimento) => (
                        <ListItemButton
                          key={estabelecimento.id}
                          sx={{ padding: '0px' }}
                          onClick={() => {
                            onSelectEstablishment(estabelecimento.id);
                          }}
                        >
                          <ListItemText>
                            {estabelecimento.estabelecimentoNome}
                          </ListItemText>
                          <ListItemIcon sx={{ minWidth: '0px' }}>
                            <KeyboardArrowRightIcon />
                          </ListItemIcon>
                        </ListItemButton>
                      )
                    )}
                  </List>
                </h2>
              </Paper>
            )}
          </>
        ) : (
          <>
            {statePoppers.firstPopper && (
              <Paper
                ref={firstPopperRef}
                elevation={3}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  position: 'absolute',
                  width: `${statePoppers.firstPopper.width}px`,
                  top: `calc(${statePoppers.firstPopper.top}px + 10px)`,
                  left: `${statePoppers.firstPopper.left}px`,
                  padding: '10px 20px',
                }}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Estado do Ambiente de Trabalho
                  <List>
                    {establishmentsList.map((item, index) => {
                      return (
                        <ListItemButton
                          key={item.estadoNome}
                          onClick={(index) => {
                            setLastStateNameSelected(item.estadoNome);
                            closePopperWhenClickSameOption(item.estadoNome);
                            handleSecondPopper(index, item.estadoNome);
                          }}
                          sx={{ padding: '0px' }}
                        >
                          <ListItemText>{item.estadoNome}</ListItemText>
                          <ListItemIcon sx={{ minWidth: '0px' }}>
                            {statePoppers.secondPopper &&
                            indexSelectedItem.firstPopper === index ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </h2>
              </Paper>
            )}

            {statePoppers.secondPopper && selectedEstadoData && (
              <Paper
                ref={secondPopperRef}
                elevation={3}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  position: 'absolute',
                  top: `calc(${
                    statePoppers.firstPopper.top +
                    statePoppers.firstPopper.height
                  }px + 20px)`,
                  width: `${statePoppers.firstPopper.width}px`,
                  left: `${statePoppers.firstPopper.left}px`,
                  padding: '10px 20px',
                }}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Município do Ambiente de Trabalho
                  <List>
                    {selectedEstadoData.municipios.map((item, index) => {
                      return (
                        <ListItemButton
                          onClick={(index) =>
                            handleThirdPopper(index, item.municipioNome)
                          }
                          sx={{ padding: '0px' }}
                        >
                          <ListItemText>{item.municipioNome}</ListItemText>
                          <ListItemIcon sx={{ minWidth: '0px' }}>
                            {statePoppers.thirdPopper &&
                            indexSelectedItem.secondPopper === index ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </h2>
              </Paper>
            )}

            {statePoppers.thirdPopper && selectedMunicipioData && (
              <Paper
                elevation={3}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  position: 'absolute',
                  top: `calc(${
                    statePoppers.secondPopper.top +
                    statePoppers.secondPopper.height
                  }px + 20px)`,
                  width: `${statePoppers.firstPopper.width}px`,
                  left: `${statePoppers.firstPopper.left}px`,
                  padding: '10px 20px',
                }}
              >
                <h2 style={{ margin: 0, fontSize: '20px' }}>
                  Selecione o Ambiente de Trabalho
                  <List>
                    {selectedMunicipioData?.estabelecimentos.map((item) => {
                      return (
                        <ListItemButton
                          sx={{ padding: '0px' }}
                          onClick={() => {
                            onSelectEstablishment(item.id);
                          }}
                        >
                          <ListItemText>
                            {item.estabelecimentoNome}
                          </ListItemText>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </h2>
              </Paper>
            )}
          </>
        )}
      </ContainerEstablishment>
      {openInformationPaper && (
        <Paper
          sx={{
            position: 'absolute',
            top: '100px',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '4px',
            maxWidth: '450px',

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-10px',
              left: '20%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: `10px solid white`,
            },
          }}
        >
          <h3 style={{ margin: 0 }}>Alterne entre seus estabelecimentos </h3>
          <p>
            Clique no botão acima para mudar o seu acesso entre estabelecimentos
            vinculados a sua conta
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                onChange={() =>
                  setDontShowAgainCheckbox(!dontShowAgainCheckbox)
                }
                sx={{
                  // marginRight: ' 10px',
                  padding: '0 10px',
                  '&.Mui-checked': {
                    color: '#238884',
                  },
                }}
              />
              <p style={{ margin: 0, fontSize: '13px', fontWeight: 'bold' }}>
                NÃO MOSTRAR NOVAMENTE
              </p>
            </div>

            <Button
              onClick={handleCloseInformationPaper}
              variant="contained"
              style={{ height: '30px' }}
            >
              Entendido
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default SelectEstablishment;
