import { useContext, useEffect } from 'react';
import useStatesFields from '../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../lib/auth';
import api from '../../api';
import { PerfilContext } from '../../context/PerfilContext';

const useSetFilterByPerfil = ({
  setListFilter,
  setSelectedOptionsLaboratorio,
  setListFilterShow,
  setShowTableData,
  loadReports,
  setOptionsMunicipios,
  setOptionsEstados,
  setOptionsEstabelecimentos,
  setOptionsLaboratorios,
  setOptionsProfisisonaisLaboratorio,
  setValue,
  fieldProfissionalResultado,
  fieldLaboratorioResultado,
  selectedLaboratorio,
  setSelectedLaboratorio,
  setDisabledEstadoInput,
  setDisabledMunicipioInput,
  setDisabledEstabelecimentoInput,
  pageContainInputLaboratorio,
}: Partial<any>) => {
  const {
    isSuperAdmin,
    isEstadual,
    isMunicipal,
    isAdminLaboratorio,
    isPadraoEstabelecimento,
    isMedicoLaboratorio,
    isOutrosLaboratorio,
    estabelecimentoIdUser,
    estabelecimentoNameUser,
    municipioUserId,
    municipioUserName,
    estadoUserSigla,
    userName,
    userCpf,
  } = useContext(PerfilContext);
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };
  const handleSetFilterShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) => {
      const exists = prev.some((item) => item.inputName === inputName);
      return exists
        ? prev
        : [...prev, { inputName: inputName, value: newValue }];
    });
  };

  const setDefaultEstado = ({ sigla }) => {
    const objEstado = {
      label: sigla,
      value: sigla,
    };
    setOptionsEstados([objEstado]);
    setValue('estado', [objEstado]);
    handleSetFilter(`Estado: ${sigla}`, 'estado');
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    const objMunicipio = {
      label: municipioNome,
      value: municipioId,
    };
    setOptionsMunicipios([objMunicipio]);
    setValue('municipio', [objMunicipio]);

    handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
  };

  const setDefaultEstabelecimento = ({
    estabelecimentoId,
    estabelecimentoNome,
  }) => {
    const objEstabelecimento = {
      label: estabelecimentoNameUser,
      value: estabelecimentoIdUser,
    };
    setOptionsEstabelecimentos([objEstabelecimento]);
    setValue('estabelecimento', [objEstabelecimento]);

    handleSetFilter(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );
  };

  const setDefaultProfissionalResponsavel = ({
    profissionalId,
    profissionalNome,
  }) => {
    const objProf = {
      idprofissional: profissionalId,
      nomeprofissional: profissionalNome,
      label: profissionalNome,
    };

    handleSetFilter(
      `Profissional Responsável Pelo Resultado: ${profissionalNome}`,
      fieldProfissionalResultado
    );
    setOptionsProfisisonaisLaboratorio([objProf]);

    setValue(fieldProfissionalResultado, objProf);
  };

  const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
    const objLab = [
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ];
    setOptionsLaboratorios(objLab);
    setSelectedOptionsLaboratorio(objLab);
    setSelectedLaboratorio(objLab);

    handleSetFilter(
      `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
      fieldLaboratorioResultado
    );
  };

  const actionsWhenUserIsEstadual = () => {
    setDisabledEstadoInput(true);
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
  };

  const actionsWhenUserIsMunicipal = () => {
    setDisabledMunicipioInput(true);
    setDisabledEstadoInput(true);

    setDefaultEstado({
      sigla: estadoUserSigla,
    });
    setDefaultMunicipio({
      municipioId: String(municipioUserId),
      municipioNome: municipioUserName,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');

    setShowTableData(true);
  };

  const actionsWhenUserIsAdminLaboratorio = () => {
    setDisabledEstadoInput(true);
    setDefaultEstado({
      sigla: estadoUserSigla,
    });

    if (pageContainInputLaboratorio) {
      setDefaultLaboratorio({
        laboratorioId: estabelecimentoIdUser,
        laboratorioName: estabelecimentoNameUser,
      });
    }
  };

  const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
    actionsWhenUserIsMunicipal();

    setDisabledEstabelecimentoInput(true);

    setDefaultEstabelecimento({
      estabelecimentoId: estabelecimentoIdUser,
      estabelecimentoNome: estabelecimentoNameUser,
    });

    handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    handleSetFilterShow(`Município: ${municipioUserName}`, 'municipio');
    handleSetFilterShow(
      `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      'estabelecimento_saude'
    );
  };
  const actionsWhenUserIsMedicoLaboratorio = async () => {
    actionsWhenUserIsAdminLaboratorio();

    setShowTableData(false);

    const profissional = await api.get('/profissionais', {
      params: {
        cpf: userCpf,
      },
    });

    const profissionalId = profissional?.data?.data?.[0]?.id;

    // loadReports({
    //   payload: {
    //     estados: estadoUserSigla,

    //     laboratorioLaudoId: estabelecimentoIdUser,
    //     profissionalResultadoId: profissionalId,
    //     page: 1,
    //     size: 25,
    //   },
    // });

    setDefaultProfissionalResponsavel({
      profissionalId: profissionalId,
      profissionalNome: userName,
    });

    handleSetFilter(
      `Profissional Responsável Pelo Resultado: ${userName}`,
      fieldProfissionalResultado
    );

    // handleSetFilterShow(`Estados: ${estadoUserSigla}`, 'estados');
    // handleSetFilterShow(
    //   `Laboratório Responsável Pelo Laudo: ${estabelecimentoNameUser}`,
    //   'laboratorioId'
    // );
    // handleSetFilterShow(
    //   `Profissional Responsável Pelo Resultado: ${userName}`,
    //   'profissionalLaudoId'
    // );
  };

  const setFiltersByPerfil = () => {
    if (isSuperAdmin) {
      console.log('1');
      return;
    }

    if (isEstadual) {
      console.log('2');

      actionsWhenUserIsEstadual();
    }

    if (isMunicipal) {
      console.log('3');

      actionsWhenUserIsMunicipal();

      loadReports({
        payload: {
          estado: estadoUserSigla,
          municipios: municipioUserId,
          page: 1,
          size: 25,
        },
      });
    }

    if (isAdminLaboratorio || isOutrosLaboratorio) {
      console.log('4');

      actionsWhenUserIsAdminLaboratorio();
    }

    if (isPadraoEstabelecimento) {
      console.log('5');

      actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

      loadReports({
        payload: {
          estado: estadoUserSigla,
          municipios: municipioUserId,
          estabelecimentoSaudeId: estabelecimentoIdUser,
          page: 1,
          size: 25,
        },
      });
    }

    if (isMedicoLaboratorio) {
      console.log('6');

      actionsWhenUserIsMedicoLaboratorio();
    }
  };

  return {
    setFiltersByPerfil,
  };
};

export default useSetFilterByPerfil;
