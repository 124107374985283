import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import RowsPersonalData from './RowsPersonalData';
import RowsAddressData from './RowsAddressData';
import RowsAnamneseData from './RowsAnamneseData';
import RowsClinico from './RowsClinico';
import RowsResultadoData from './RowsResultadoData';
import { formatCpf } from '../../../../../utils/formatters';
import moment from 'moment';
import calculaIdade from '../../../../../utils/calculaIdade';

const Rows = ({ tableData }) => {
  const formatFaixa = (faixa) => {
    const split = faixa?.split('_');
    return split[1] ? `${split[0]} - ${split[1]}` : split;
  };

  const meioRastreamento = {
    listagem: 'Banco de Dados',
    outros: 'Outros',
  };
  return (
    <TableBody>
      {tableData?.data?.map((row) => (
        <TableRow key={row?.pessoa?.id}>
          <TableCell sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body1" fontSize="14px">
              {String(row?.prioridade)}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body1" fontSize="14px">
              {formatFaixa(row.faixa)}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.idade} Anos
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body1" fontSize="14px">
              {row?.dt_coleta_cito
                ? moment(row?.dt_coleta_cito).format('DD/MM/YYYY')
                : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.has_teste_hpv === 'Sim' ? 'Realizado' : 'Não Realizado'}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body1" fontSize="14px">
              {row?.nome}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body1" fontSize="14px">
              {row?.apelido}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.cpf ? formatCpf(row?.cpf) : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.n_sus}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.dt_nascimento
                ? moment(row?.dt_nascimento).format('DD/MM/YYYY')
                : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.contato_preferencial}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.logradouro}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.numero}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.bairro}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.complemento}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.referencia}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.cep}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.municipio}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.estado}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.celular}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.nome_estabelecimento}
            </Typography>
          </TableCell>
          {/* <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.cnes_estabelecimento}
            </Typography>
          </TableCell> */}
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.acs_nome}
            </Typography>
          </TableCell>

          {/* <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.has_teste_hpv}
            </Typography>
          </TableCell> */}
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.has_procedimento}
            </Typography>
          </TableCell>
          {/* <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.coleta_nome}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.dt_coleta_hpv
                ? moment(row.dt_coleta_hpv).format('DD/MM/YYYY')
                : ''}
            </Typography>
          </TableCell> */}
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {meioRastreamento[row.rastreamento]}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default Rows;
