import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material/styles';
import ModalAmostragem from './Components/ModalAmostragem';

const ButtonGreenLight = styled(Button)({
  boxShadow: 'none',
  border: '1px solid',
  backgroundColor: '#D7EBEB',
  borderColor: 'rgba(35, 136, 132, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(35, 136, 132, 0.5)',
    borderColor: 'rgba(35, 136, 132, 0.5)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'rgba(35, 136, 132, 0.5)',
    borderColor: 'rgba(35, 136, 132, 0.3)',
  },
  '&:focus': {
    boxShadow: '0 0 0 0px rgba(35, 136, 132, 0.5)',
  },
});

const options = ['XLSX', 'CSV'];

export default function ButtonWithMenu({
  prepareDownload,
  openModalAmostragem,
  setOpeModalAmostragem,
  stringToModalExportActualList,
  totalItems,
  disabled,
  title,
  titleTooltip,
}: any) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: string
  ) => {
    setSelectedOption(option);
    setOpen(false);
    setOpeModalAmostragem(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {openModalAmostragem && (
        <ModalAmostragem
          title={title}
          setSelectedOption={setSelectedOption}
          openModalAmostragem={openModalAmostragem}
          setOpeModalAmostragem={setOpeModalAmostragem}
          selectedOption={selectedOption}
          prepareDownload={prepareDownload}
          stringToModalExportActualList={stringToModalExportActualList}
          totalItems={totalItems}
          titleTooltip={titleTooltip}
        />
      )}
      <ButtonGroup
        ref={anchorRef}
        aria-label="split button"
        sx={{ height: '42px' }}
      >
        <Button
          variant="outlined"
          data-testid="exportButton"
          onClick={handleToggle}
          disabled={disabled}
        >
          EXPORTAR
        </Button>
        <ButtonGreenLight
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          variant="contained"
          disabled={disabled}
          data-testid="exportButtonDropdown"
        >
          <ArrowDropDownIcon color="primary" />
        </ButtonGreenLight>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '999999999999999999' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ width: '155px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option === 'XLSX' ? (
                        <p>
                          XLSX <br /> (Planilha)
                        </p>
                      ) : (
                        option
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
