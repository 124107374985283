import { useRef } from 'react';

const useDebounce = (fn, timer) => {
  const timeoutRef = useRef(null);

  const debounceChange = (...args) => {
    window.clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, timer);
  };

  return debounceChange;
};

export default useDebounce;
