import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';

const useHandleChangesFilters = ({
  setCidades,
  setEstabelecimentos,
  setValue,
  setListFilter,
  estados,
}) => {
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };

  const getCidadesEstabelecimentos = async (sigla) => {
    const response = await getCidades(sigla);
    setCidades(response);
  };

  const getEstabelecimento = async ({ municipio }) => {
    const response = await getEstabelecimentos({
      municipio,
    });
    setEstabelecimentos(response.data);
  };

  const handleChangeUf = ({ newValue, onChange }) => {
    onChange(newValue);
    if (newValue) {
      const estado = estados.find((item) => item.nome === newValue);
      if (estado) {
        getCidadesEstabelecimentos(estado.sigla);
      }
    }
    handleSetFilter(newValue, 'estado');
  };

  const handleChangeCidade = ({ newValue, onChange }) => {
    onChange(newValue);
    if (newValue) {
      getEstabelecimento({ municipio: newValue });
    }
    handleSetFilter(newValue, 'municipio');
  };

  const handleChangeEstabelecimento = ({ newValue, onChange }) => {
    if (newValue) {
      onChange(newValue);
      handleSetFilter(newValue?.nome_fantasia, 'estabelecimento_saude');
    } else {
      setValue('estabelecimento_saude', null);
      handleSetFilter('', 'estabelecimento_saude');
    }
  };

  const handleChangeRealizouAtendimento = (
    newValue,
    setShowMotivoNaoAtendimento,
    onChange
  ) => {
    onChange(newValue);

    if (!newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', null);
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('', 'realizou_atendimento');
      return;
    }

    const isRealizadoAtendimento = newValue === 'Realizado';
    setShowMotivoNaoAtendimento(!isRealizadoAtendimento);

    if (isRealizadoAtendimento) {
      setValue('atendimento_status', 'REALIZADO');
      handleSetFilter('REALIZADO', 'atendimento_status');
      handleSetFilter('Sim', 'realizou_atendimento');
    } else {
      setValue('atendimento_status', '');
      handleSetFilter('Não', 'realizou_atendimento');
    }
  };

  const handleChangeFilter = (newValue: string, field: string, onChange: (value: any) => void) => {
    onChange(newValue);
    handleSetFilter(newValue, field);
  };

  const handleChangeDatePicker = ({ date, onChange, datePickerName }) => {
    const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
    onChange(date);
    handleSetFilter(formattedDate, datePickerName);
  };

  return {
    handleSetFilter,
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleChangeRealizouAtendimento,
    handleChangeFilter,
    handleChangeDatePicker,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  };
};

export default useHandleChangesFilters;