const TotalPatientsTablesReports = ({ watch, totalItems }) => {
  const secondNumber = watch('size') * watch('page');

  const firstNumber = secondNumber - watch('size') + 1;

  return (
    <p style={{ marginTop: '35px' }}>
      Exibindo {totalItems ? `${firstNumber} - ${secondNumber} de ` : ''}{' '}
      {totalItems ?? 0} pacientes
    </p>
  );
};

export default TotalPatientsTablesReports;
