import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsIntraepitelial,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterToShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
  isAdminLaboratorio,
  setSelectedServicos,
  setSelectedTipoEzt,
  setSelectedAnestesia,
  setSelectedLocal,
  setSelectedEzt,
  setSelectedTipoProcedimento,
  setSelectedHasHistoPos,
  setSelectedHasHistoPre,
  isPadrao,
  isHospital,
}) => {
  const fieldsToClearWhenIsPadrao: any[] = [
    'estabelecimento_saude',
    'indicacao_ezt',
    'tipo_ezt',
    'responsavel_coleta',
    'estabelecimentoLaudoId',
    'search',
    'natureza',
    'tipo_virus',
    'dt_coleta_inicio',
    'dt_coleta_fim',
  ];

  const fieldsToClearWhenIsPadraoAndNotIsHospital: any[] = [
    'histo_pos',
    'histo_pre',
    'indicacao',
    'servico_saude',
    'profissional_resp',
    'local_realizacao',
    'exerese',
    'anestesia',
    'procedimento',
    'dt_ini_resultado',
    'dt_fin_resultado',
    'search',
  ];

  const fieldsToClearWhenIsMunicipal = [
    'servico_saude',
    'profissionalId',
    ...fieldsToClearWhenIsPadrao,
  ];

  const fieldsToClearWhenEstadual = [
    'municipio',
    ...fieldsToClearWhenIsMunicipal,
  ];

  const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterToShow((prev) =>
      prev?.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);
    setShowTableData(false);
    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    setSelectedServicos([]);
    setSelectedTipoEzt([]);

    setSelectedAnestesia([]);

    setSelectedLocal([]);

    setSelectedEzt([]);

    setSelectedTipoProcedimento([]);

    setSelectedHasHistoPos([]);
    setSelectedHasHistoPre([]);

    if (isHospital) {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, null);
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   municipio: currentEstablishment.endereco.municipio.nome,
      //   estabelecimentoId: currentEstablishment.id,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    } else if (isPadrao && !isHospital) {
      fieldsToClearWhenIsPadraoAndNotIsHospital.forEach((field) => {
        setValue(field, null);
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
    } else {
      setEstadosSelected([]);
      setMunicipiosSelected([]);
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
    }

    // if (permissao === 'isMunicipal') {
    //   fieldsToClearWhenIsMunicipal.forEach((field) => {
    //     setValue(field, '');
    //     handleSetFilter('', field);
    //     handleSetFilterToShow('', field);
    //   });

    //   setEstabelecimentosSelected([]);

    //   // const payload = {
    //   //   estado: currentEstablishment.endereco.municipio.estado.nome,
    //   //   municipio: currentEstablishment.endereco.municipio.nome,
    //   //   size: watch().size,
    //   //   page: watch().page,
    //   // };

    //   // if (showTableData) {
    //   //   loadReportsHpv(payload);
    //   // }
    // }

    // if (permissao === 'isEstadual') {
    //   fieldsToClearWhenEstadual.forEach((field) => {
    //     setValue(field, '');
    //     handleSetFilter('', field);
    //     handleSetFilterToShow('', field);
    //   });

    //   setEstabelecimentosSelected([]);
    //   setMunicipiosSelected([]);

    //   // const payload = {
    //   //   estado: currentEstablishment.endereco.municipio.estado.nome,
    //   //   size: watch().size,
    //   //   page: watch().page,
    //   // };

    //   // if (showTableData) {
    //   //   loadReportsHpv(payload);
    //   // }
    // }

    // if (permissao === 'isSuperAdmin') {
    //   fieldsToClearWhenSuperAdmin.forEach((field) => {
    //     setValue(field, '');
    //     handleSetFilter('', field);
    //   });

    //   setEstabelecimentosSelected([]);
    //   setMunicipiosSelected([]);
    //   setEstadosSelected([]);

    //   // const payload = {
    //   //   size: watch().size,
    //   //   page: watch().page,
    //   // };

    //   // if (showTableData) {
    //   //   loadReportsHpv(payload);
    //   // }
    // }
  };

  return { clearValues };
};

export default useClearValues;
