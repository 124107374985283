/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { toast } from 'react-toastify';
import { useState, useEffect, useCallback, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import api from '../../../api';
import { useStyles } from '../RegisterExam.style';
import jwtDecode from 'jwt-decode';
import { getAccessToken, getUserToken } from '../../../lib/auth';
import { TokenData, User } from '../../../components/ModalStatus';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Anamnese, Escolha, Motivo } from './Hpv';
import { PerfilContext } from '../../../context/PerfilContext';
import moment from 'moment';
import useDebounce from '../../../utils/hooks/useDebounce';

enum RedeAtendimento {
  RedeSus = '1',
  SaudeSuplementar = '2',
}

enum Origem {
  Biopsia = '1',
  PecaCirurgica = '2',
}

enum MargemCirurgica {
  Livre = '1',
  Comprometidas = '2',
  NaoAvaliaveis = '3',
}

enum NeoplasticaEscamosa {
  Nic1 = '1',
  Nic2 = '2',
  Nic3 = '3',
  CeMicroinvasivo = '4',
  CeInvasivo = '5',
  NaoAvaliavel = '6',
}

// interface NeoplasticaGlandular {
//   inSitu: boolean;
//   invasor: boolean;
//   outras: boolean;
// }

// interface LesoesBenignas {
//   metaplasia: boolean;
//   polipo: boolean;
//   cervicite: boolean;
//   hpv: boolean;
// }

// interface LesoesNeoplasticas {
//   neoplasticaEscamosa: NeoplasticaEscamosa;
//   neoplasticaGlandular: NeoplasticaGlandular;
// }

// interface ResultadoExame {
//   dtResultado: string;
//   lesoesBenignas: LesoesBenignas;
//   lesoesNeoplasticas: LesoesNeoplasticas;
// }

interface IHistopatologia {
  dt_coleta: Date;
  rede_atendimento: RedeAtendimento;
  protocolo: string;
  dt_resultado: Date;
  origem: Origem;
  margem_cirurgica: MargemCirurgica;
  metaplasia_escamosa: boolean;
  polipo_endocervical: boolean;
  cervicite_cronica: boolean;
  hpv: boolean;
  neoplastia_escamosa: NeoplasticaEscamosa | null;
  adenocarcionoma_in_situ: boolean;
  adenocarcionoma_invasor: boolean;
  outras_neoplasias: boolean | null | undefined;
  diagnostico_descritivo: string;
  pessoaId: number;
  profissionalId: Partial<any>;
  profissionalCNS: string;
  laboratorioResponsavel: any;
  responsavelId: number;
  responsavelCNS: string;
  estabelecimentoSaudeId: number;
  profissionalCBO: string;
  laboratorioResponsavelId: string;
}

interface Inputs {
  histopatologia: IHistopatologia;
  anamnese: Anamnese;
  laboratorio_responsavel: any;
  cnes: any;
}

interface Laboratory {
  id: number;
  razao_social: string;
  nome_fantasia: string | null;
  cnes: string;
  cnpj_mantenedor: string;
  inativo: boolean;
  dt_inicio: Date;
  dt_fim: Date | null;
}

const schema = Yup.object({
  cnes: Yup.string().required('Campo Obrigatório'),
  histopatologia: Yup.object({
    dt_coleta: Yup.string().required('Campo Obrigatório.'),
    dt_resultado: Yup.string().required('Campo Obrigatório.'),
    origem: Yup.string().required('Campo Obrigatório'),
    profissionalId: Yup.object({
      id: Yup.number().required('Campo Obrigatório'),
    }),
    rede_atendimento: Yup.string().required('Campo Obrigatório'),
  }),
  // anamnese: Yup.object({
  //   motivo: Yup.string().required('Campo Obrigatório'),
  //   papanicolaou: Yup.string().required('Campo Obrigatório'),
  //   ano_papanicolaou: Yup.number().when('papanicolaou', {
  //     is: (papanicolaou: any) => {
  //       return papanicolaou === Escolha.Sim;
  //     },
  //     then: Yup.number().required('Campo Obrigatório'),
  //   }),
  //   diu: Yup.string().required('Campo Obrigatório'),
  //   gravida: Yup.string().required('Campo Obrigatório'),
  //   anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
  //   hormonio: Yup.string().required('Campo Obrigatório'),
  //   radioterapia: Yup.string().required('Campo Obrigatório'),
  //   dt_menstruacao_desconhecida: Yup.boolean().default(false),
  //   dt_menstruacao: Yup.string().when('dt_menstruacao_desconhecida', {
  //     is: (checkedMenstruacao: boolean) => {
  //       return checkedMenstruacao === false;
  //     },
  //     then: Yup.string().required('Campo Obrigatório'),
  //   }),
  //   sangramento_relacao: Yup.string().required('Campo Obrigatório'),
  //   sangramento_menopausa: Yup.string(),
  // }),
});

export const Histopatologia = ({ data }) => {
  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const navigate = useNavigate();
  const styles = useStyles();
  const location = useLocation();
  const [, setPacienteData] = React.useState<any>();
  const [loadingLaboratorios, setLoadingLaboratorios] = useState(false);
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any[]>(
    []
  );
  const [cnesEstabelecimento, setCnesEstabelecimento] = useState<any>();
  const [checkedMenstruacao, setCheckedMenstruacao] =
    React.useState<boolean>(false);
  const [optionsResponsaveis, setOptionsResponsaveis] = React.useState<any>([]);
  const [disableInputResponsavel, setDisableInputResponsavel] =
    React.useState(false);
  const [valueGlandular, setValueGlandular] = useState<any>();

  const [disableLaboratorio, setDisableLaboratorio] = useState(false);
  const [disableCnes, setDisableCnes] = useState(false);

  const {
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
    control,
    watch,
    register,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  // const rhf = watch();

  const [user, setUser] = useState({} as User);

  const [laboratory, setLaboratory] = React.useState<Laboratory[]>([]);

  const motivoAnmnese = watch('anamnese.motivo');

  const { hasPermission } = React.useContext(PerfilContext);

  const currentUser = getUserToken();

  const isAdminLaboratorio =
    currentUser.permissao_atual.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual.nome === 'Admin Estabelecimento';

  const isMedicoLaboratorio =
    currentUser.permissao_atual.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual.id === 7;

  const isOutrosLaboratorio =
    currentUser.permissao_atual.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual.id === 8;

  const onSubmit = async (data: Inputs) => {
    if (
      !data?.histopatologia?.profissionalId?.id ||
      data?.histopatologia?.profissionalId?.id === 0
    ) {
      toast.error('Selecione um profissional', { position: 'bottom-right' });
      return;
    }
    // if (!motivoAnmnese) {
    //   setError('anamnese.motivo', {
    //     type: 'manual',
    //     message: 'Campo obrigatório',
    //   });

    //   return;
    // }
    const objPaylo = {
      ...data.histopatologia,
      profissionalId: data?.histopatologia?.profissionalId?.id,
      adenocarcionoma_in_situ:
        valueGlandular === 'adenocarcionoma_in_situ' ? true : undefined,
      adenocarcionoma_invasor:
        valueGlandular === 'adenocarcionoma_invasor' ? true : undefined,
      // anamnese: { ...data.anamnese },
    };

    try {
      clearErrors();
      await api.post('/histopatologia', {
        ...objPaylo,
      });
      navigate(`/pacientes/${data.histopatologia.pessoaId}`);
    } catch (error: any) {
      toast.error(error?.error?.message, { position: 'bottom-right' });
      console.error('ops! ocorreu um erro' + error);
    }
  };

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${data.patientData.id}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    }
    setValue('anamnese.dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
    clearErrors('anamnese.dt_menstruacao');
  };

  const handleChangeCnes = (cnes) => {
    if (cnes) {
      setLoadingLaboratorios(true);

      getOptionsEstabelecimentos({ cnes });
    } else {
      setOptionsEstabelecimentos([]);
      setLoadingLaboratorios(false);
    }
  };

  const debounceChange = useDebounce(handleChangeCnes, 1000);

  const getOptionsProfissionais = async (estab_id) => {
    if (!estab_id) {
      return;
    }
    const response = await api.get('/profissionais/cbo/list', {
      params: {
        cboCodes: '225148,225305,225325,225335',
        estabelecimentoId: estab_id,
      },
    });

    setOptionsResponsaveis(response.data);
  };

  const { id } = useParams();

  const outrasNeoplasias = watch('histopatologia.outras_neoplasias');

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
  };

  const styleCheckBox = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const setDefaultProfissional = async () => {
    setDisableInputResponsavel(true);
    try {
      const response = await api.get('/profissionais', {
        params: { cpf: currentUser.pessoa.cpf },
      });

      const obj = {
        id: response.data.data[0].id,
        cns: response.data.data[0].cns,
        cbo: response.data.data[0].cbo,

        pessoa: {
          id: response.data.data[0].pessoa.id,
          nome: response.data.data[0].pessoa.nome,
        },
      };

      setValue('histopatologia.profissionalCBO', obj?.cbo);
      setValue('histopatologia.profissionalCNS', obj?.cns);
      setValue('histopatologia.profissionalId', obj);
    } catch (error) {}
  };

  const setDefaultLaboratorio = () => {
    const {
      estabelecimentoId,
      estabelecimento_saude: { nome_fantasia, cnes },
    } = currentUser;

    const objUserLaboratorio = { id: estabelecimentoId, nome_fantasia, cnes };

    setDisableLaboratorio(true);
    setDisableCnes(true);
    setOptionsEstabelecimentos([objUserLaboratorio]);
    getOptionsProfissionais(estabelecimentoId);
    setValue('histopatologia.laboratorioResponsavel', objUserLaboratorio);
    setCnesEstabelecimento(cnes);
    setValue('cnes', cnes);
  };

  const getOptionsEstabelecimentos = async ({ cnes }) => {
    const response = await api.get('/estabelecimentos-saude/laboratorios', {
      params: {
        cnes,
      },
    });

    setOptionsEstabelecimentos(
      response.data.data.map((lab: any) => {
        return { id: lab.id, nome_fantasia: lab.nome_fantasia, cnes: lab.cnes };
      })
    );

    if (response.data.data.length > 0) {
      clearErrors('cnes');
      setValue('histopatologia.laboratorioResponsavel', {
        id: response.data.data?.[0].id,
        nome_fantasia: response.data.data?.[0].nome_fantasia,
        cnes: response.data.data?.[0].cnes,
      });

      getOptionsProfissionais(response.data.data?.[0].id);
    } else {
      setValue('histopatologia.laboratorioResponsavel', null);
      setError('cnes', { type: 'manual', message: ' CNES inválido' });
      setOptionsResponsaveis([]);
    }

    setLoadingLaboratorios(false);
  };

  const setValuesByPerfil = () => {
    if (isAdminLaboratorio || isOutrosLaboratorio || isMedicoLaboratorio) {
      setDefaultLaboratorio();
    }

    if (isMedicoLaboratorio) {
      setDefaultProfissional();
    }

    if (isOutrosLaboratorio) {
      getOptionsProfissionais(currentUser.estabelecimentoId);
    }
  };

  useEffect(() => {
    setValuesByPerfil();
  }, []);

  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPacienteData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadData(id);
  }, [id]);

  useEffect(() => {
    if (outrasNeoplasias === true) {
      setValue('histopatologia.neoplastia_escamosa', null);
      setValueGlandular(undefined);
    }
  }, [outrasNeoplasias]);

  useEffect(() => {
    setPacienteData(location.state);
    async function loadResponsaveis() {
      // const estabelecimentos = await api.get("/estabelecimentos-saude")
      await api
        .get('/profissionais')
        .then((response) => {
          //setValue("histopatologia.estabelecimentoSaudeId", estabelecimentos.data.data[3].id)
          setValue('histopatologia.responsavelId', response.data.data[0].id);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    // loadResponsaveis();
  }, []);

  useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());
    if (token) {
      setUser(token.user);
    }
  }, []);

  useEffect(() => {
    // getOptionsEstabelecimentos();
    setValue('histopatologia.pessoaId', location?.state?.patientData?.id);
    //setValue('histopatologia.laboratorioResponsavelId', '1474');
    // setValue('histopatologia.rede_atendimento', RedeAtendimento.RedeSus);
    /*setValue(
      'laboratorio_responsavel',
      'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA'
    );*/
  }, [location?.state?.patientData?.id]);

  useEffect(() => {
    if (!hasPermission('VIEW_EXAME_HISTOPATOLOGIA_RESULTADO')) {
      navigate('/home');
    }
  }, []);

  // useEffect(() => {
  //   const laboratorioSelected = optionsEstabelecimentos?.find(
  //     (lab) => lab.id === laboratorioUserId
  //   );

  //   if (isAdminLaboratorio || isMedicoLaboratorio || isOutrosLaboratorio) {
  //     // setDisableLaboratorio(true);

  //     getOptionsProfissionais(laboratorioSelected?.id);

  //     setCnesEstabelecimento(laboratorioSelected?.cnes);

  //     setValue('histopatologia.laboratorioResponsavel', laboratorioSelected);

  //     setValue('histopatologia.laboratorioResponsavelId', laboratorioUserId);
  //   }

  //   if (isMedicoLaboratorio) {
  //     const pessoa = getUserToken();

  //     const profissional = optionsResponsaveis.find(
  //       (resp) => resp?.pessoa?.id === pessoa?.pessoa?.id
  //     );

  //     setDisableInputResponsavel(true);

  //     setValue('histopatologia.profissionalCBO', profissional?.cbo);

  //     setValue('histopatologia.profissionalCNS', profissional?.cns);

  //     setValue('histopatologia.profissionalId', {
  //       id: profissional?.id,

  //       pessoa: {
  //         id: profissional?.pessoa?.id,

  //         nome: profissional?.pessoa?.nome,
  //       },
  //     });
  //   }
  // }, [
  //   isAdminLaboratorio,
  //   isMedicoLaboratorio,
  //   isOutrosLaboratorio,
  //   optionsEstabelecimentos,
  // ]);

  // useEffect(() => {
  //   if (isMedicoLaboratorio) {
  //     getOptionsProfissionais(laboratorioUserId);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isMedicoLaboratorio) {
  //     const pessoa = getUserToken();

  //     setDefaultProfissional(pessoa.pessoa.cpf);

  //     setDisableInputResponsavel(true);
  //   }
  // }, [optionsResponsaveis, isMedicoLaboratorio]);

  useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());

    if (token) {
      setUser(token.user);
    }
  }, []);

  return (
    <Box mt={3}>
      <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
        <DialogTitle>Cancelar cadastro de resultado do exame</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente cancelar o cadastro de resultado do exame ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogCancel}>Não</Button>
          <Button onClick={handleCloseConfirmCancel} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Identificação do Laboratório
            </Typography>

            <Box my={3}>
              <Divider />
            </Box>

            <FormControl>
              <FormLabel
                required
                sx={styleFormLabel}
                id="rede-atendimento"
                error={!!errors.histopatologia?.rede_atendimento}
              >
                Rede de Atendimento
              </FormLabel>
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <RadioGroup onChange={onChange} value={value} row>
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Rede SUS"
                        // disabled
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Saúde suplementar"
                        // disabled
                      />
                    </RadioGroup>
                  );
                }}
                name="histopatologia.rede_atendimento"
                control={control}
              />
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {errors.histopatologia?.rede_atendimento?.message}
              </FormHelperText>
            </FormControl>

            <Box mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <StyledAsteriskTextField
                    fullWidth
                    label="CNES do Laboratório"
                    required
                    size="small"
                    error={!!errors?.cnes}
                    helperText={errors?.cnes?.message}
                    value={cnesEstabelecimento || ''}
                    variant="outlined"
                    className={styles.textField}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D+/g, '');
                      if (value.length < 8) {
                        setOptionsResponsaveis([]);
                        setValue('cnes', value);
                        clearErrors('cnes');
                        setLoadingLaboratorios(true);
                        setCnesEstabelecimento(value);
                        debounceChange(value);
                        if (value) {
                          setValue('histopatologia.laboratorioResponsavel', {
                            nome_fantasia: 'Carregando...',
                          });
                        } else {
                          setValue(
                            'histopatologia.laboratorioResponsavel',
                            null
                          );
                        }
                      }
                    }}
                    disabled={disableCnes}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="histopatologia.laboratorioResponsavel"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          required
                          disabled
                          value={value?.nome_fantasia ?? ''}
                          InputLabelProps={{ shrink: !!value }}
                          variant="outlined"
                          label="Laboratório responsavél pelo Resultado"
                          size="small"
                          error={!!errors?.laboratorio_responsavel}
                          helperText={errors?.laboratorio_responsavel?.message}
                          fullWidth
                        />
                        // <Autocomplete
                        //   options={optionsEstabelecimentos}
                        //   value={value ? value : null}
                        //   disabled={disableLaboratorio}
                        //   noOptionsText={
                        //     loadingLaboratorios
                        //       ? 'Carregando...'
                        //       : 'Não há opções'
                        //   }
                        //   onChange={(_, newValue: any) => {
                        //     onChange(newValue);
                        //     setCnesEstabelecimento(newValue?.cnes);
                        //     getOptionsProfissionais(newValue?.id);
                        //     setValue('histopatologia.profissionalId', {
                        //       id: 0,
                        //     });
                        //     setValue('histopatologia.profissionalCBO', '');
                        //     setValue('histopatologia.profissionalCNS', '');
                        //   }}
                        //   getOptionLabel={(option: any) =>
                        //     option?.nome_fantasia
                        //   }
                        //   renderInput={(params) => (
                        //     <TextField
                        //       {...params}
                        //       required
                        //       variant="outlined"
                        //       label="Laboratório responsavél pelo Resultado"
                        //       size="small"
                        //       error={!!errors?.laboratorio_responsavel}
                        //       helperText={
                        //         errors?.laboratorio_responsavel?.message
                        //       }
                        //       fullWidth
                        //     />
                        //   )}
                        // />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Número do Protocolo do Exame no SISCAN"
                        size="small"
                        variant="outlined"
                        className={styles.textField}
                        onChange={onChange}
                        value={value ? value : ''}
                        type="number"
                      />
                    )}
                    name="histopatologia.protocolo"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', marginTop: '25px' }}
        >
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Exame
            </Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <Box sx={{ width: '20%' }}>
              <FormControl
                error={!!errors.histopatologia?.rede_atendimento && true}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <Controller
                    control={control}
                    name="histopatologia.dt_coleta"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        mask="__/__/____"
                        value={value ? value : null}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            required
                            {...params}
                            error={!!errors.histopatologia?.dt_coleta && true}
                            style={{ marginTop: '20px' }}
                            label="Data da Coleta"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {errors.histopatologia?.dt_coleta?.message}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box my={3}>
              <FormControl error={errors.histopatologia?.origem && true}>
                <FormLabel required sx={styleFormLabel}>
                  Origem
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                      sx={{ marginLeft: '15px' }}
                    >
                      <FormControlLabel
                        value={Origem.Biopsia}
                        control={<Radio sx={styleRadio} />}
                        label="Biópsia"
                      />
                      <FormControlLabel
                        value={Origem.PecaCirurgica}
                        control={<Radio sx={styleRadio} />}
                        label="Peça Cirúrgica"
                      />
                    </RadioGroup>
                  )}
                  name="histopatologia.origem"
                  control={control}
                />
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {errors.histopatologia?.origem?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            {watch('histopatologia.origem') === Origem.PecaCirurgica && (
              <Box my={3}>
                <FormControl
                  error={errors.histopatologia?.margem_cirurgica && true}
                >
                  <FormLabel required sx={styleFormLabel}>
                    Margens Cirúrgicas
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={onChange}
                        value={value ? value : ''}
                        row
                        sx={{ marginLeft: '15px' }}
                      >
                        <FormControlLabel
                          value={MargemCirurgica.Livre}
                          control={<Radio sx={styleRadio} />}
                          label="Livre"
                        />
                        <FormControlLabel
                          value={MargemCirurgica.Comprometidas}
                          control={<Radio sx={styleRadio} />}
                          label="Comprometidas"
                        />
                        <FormControlLabel
                          value={MargemCirurgica.NaoAvaliaveis}
                          control={<Radio sx={styleRadio} />}
                          label="Impossível de Serem Avaliadas"
                        />
                      </RadioGroup>
                    )}
                    name="histopatologia.margem_cirurgica"
                    control={control}
                  />
                </FormControl>
              </Box>
            )}
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', marginTop: '25px' }}
        >
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Resultado do Exame
            </Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <Box sx={{ width: '20%' }}>
              <FormControl
                error={!!errors.histopatologia?.dt_resultado && true}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <Controller
                    control={control}
                    name="histopatologia.dt_resultado"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        mask="__/__/____"
                        value={value ? value : null}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            error={
                              !!errors.histopatologia?.dt_resultado && true
                            }
                            style={{ marginTop: '20px' }}
                            label="Data do Resultado"
                            required
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {errors.histopatologia?.dt_resultado?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormLabel sx={styleFormLabel}>
                Lesões de Caráter Benigno
              </FormLabel>
              <Box
                sx={{
                  marginLeft: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} sx={styleCheckBox} />}
                      label="Metaplasia Escamosa"
                    />
                  )}
                  name="histopatologia.metaplasia_escamosa"
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} sx={styleCheckBox} />}
                      label="Pólipo Endocervical"
                    />
                  )}
                  name="histopatologia.polipo_endocervical"
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} sx={styleCheckBox} />}
                      label="Cervicite Crônica Inespecífica"
                    />
                  )}
                  name="histopatologia.cervicite_cronica"
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} sx={styleCheckBox} />}
                      label="Alterações Citoarquiteturais compatíveis com Ação Vital (HPV)"
                    />
                  )}
                  name="histopatologia.hpv"
                />
              </Box>
            </Box>
            <Paper variant="outlined" sx={{ width: '100%' }}>
              <Typography m={3} style={{ fontWeight: '550px' }}>
                Lesões de Caráter Neoplásico ou Pré-Neoplásico
              </Typography>

              <Box my={3}>
                <Divider />
              </Box>

              <Box m={3}>
                <FormControl
                  error={errors.histopatologia?.neoplastia_escamosa && true}
                >
                  <FormLabel sx={styleFormLabel}>Escamosa:</FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={(value) => {
                          setValue(
                            'histopatologia.outras_neoplasias',
                            undefined
                          );

                          onChange(value);
                        }}
                        value={value ? value : ''}
                        row
                        sx={{
                          marginLeft: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <FormControlLabel
                          value={NeoplasticaEscamosa.Nic1}
                          control={<Radio sx={styleRadio} />}
                          label="NIC I (Displasia leve)"
                        />
                        <FormControlLabel
                          value={NeoplasticaEscamosa.Nic2}
                          control={<Radio sx={styleRadio} />}
                          label="NIC II (Displasia moderada)"
                        />
                        <FormControlLabel
                          value={NeoplasticaEscamosa.Nic3}
                          control={<Radio sx={styleRadio} />}
                          label="NIC III (Displasia acentuada / Carcinoma in situ)"
                        />
                        <FormControlLabel
                          value={NeoplasticaEscamosa.CeMicroinvasivo}
                          control={<Radio sx={styleRadio} />}
                          label="Carcinoma Epidermóide Microinvasivo"
                        />
                        <FormControlLabel
                          value={NeoplasticaEscamosa.CeInvasivo}
                          control={<Radio sx={styleRadio} />}
                          label="Carcinoma Epidermóide Invasivo"
                        />
                        <FormControlLabel
                          value={NeoplasticaEscamosa.NaoAvaliavel}
                          control={<Radio sx={styleRadio} />}
                          label="Carcinoma Epidermóide impossível avaliar invasão"
                        />
                      </RadioGroup>
                    )}
                    name="histopatologia.neoplastia_escamosa"
                    control={control}
                  />
                </FormControl>
              </Box>
              <Box m={3} mb={3}>
                <FormLabel sx={styleFormLabel}>Glandular:</FormLabel>
                <Box
                  sx={{
                    marginLeft: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* <Controller
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} sx={styleCheckBox} />}
                        label="Adenocarcinoma in situ"
                      />
                    )}
                    name="histopatologia.adenocarcionoma_in_situ"
                  />
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} sx={styleCheckBox} />}
                        label="Adenocarcinoma invasor"
                      />
                    )}
                    name="histopatologia.adenocarcionoma_invasor"
                  /> */}
                  <RadioGroup
                    onChange={(_, value) => {
                      setValue('histopatologia.outras_neoplasias', undefined);
                      setValueGlandular(value);
                    }}
                    value={valueGlandular ? valueGlandular : null}
                  >
                    <FormControlLabel
                      value="adenocarcionoma_in_situ"
                      control={<Radio sx={styleRadio} />}
                      label="Adenocarcinoma in situ"
                    />
                    <FormControlLabel
                      value="adenocarcionoma_invasor"
                      control={<Radio sx={styleRadio} />}
                      label="Adenocarcinoma invasor"
                    />
                  </RadioGroup>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        sx={{ marginTop: '24px' }}
                        control={
                          <Checkbox
                            onChange={(_, checked) => {
                              onChange(checked);
                            }}
                            value={value}
                            checked={!!value}
                            sx={styleCheckBox}
                          />
                        }
                        label="Outras Neoplasias Malignas"
                      />
                    );
                  }}
                  name="histopatologia.outras_neoplasias"
                />
              </Box>
            </Paper>
            <Box mt={3}>
              <Controller
                name="histopatologia.diagnostico_descritivo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={3}
                    sx={{ width: '100%' }}
                    label="Diagnóstico Descritivo"
                  />
                )}
              />
            </Box>
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', marginTop: '24px' }}
        >
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Profissional Responsável
            </Typography>
            <Box my={3}>
              <Divider />
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="histopatologia.profissionalId"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={optionsResponsaveis}
                        value={value || ''}
                        onChange={(_, newValue: any) => {
                          onChange(newValue);
                          setValue(
                            'histopatologia.profissionalCBO',
                            newValue?.cbo
                          );
                          setValue(
                            'histopatologia.profissionalCNS',
                            newValue?.cns
                          );
                        }}
                        disabled={
                          disableInputResponsavel || !cnesEstabelecimento
                        }
                        getOptionLabel={(option: any) =>
                          option?.pessoa?.nome || ''
                        }
                        noOptionsText="Não há opções"
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            error={!!errors.histopatologia?.profissionalId?.id}
                            helperText={
                              errors.histopatologia?.profissionalId?.id?.message
                            }
                            variant="outlined"
                            label="Profissional Responsável pelo Resultado"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="histopatologia.profissionalCBO"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        fullWidth
                        required
                        onChange={onChange}
                        value={value ? value : ''}
                        label="CBO do Profissional"
                        size="small"
                        disabled={isMedicoLaboratorio}
                        variant="outlined"
                      />
                    );
                  }}
                ></Controller>
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="histopatologia.profissionalCNS"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        fullWidth
                        required
                        onChange={onChange}
                        value={value ? value : ''}
                        label="CNS do Profissional"
                        size="small"
                        disabled={isMedicoLaboratorio}
                        variant="outlined"
                      />
                    );
                  }}
                ></Controller>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '68px',
            borderRadius: 1,
          }}
          mb={3}
        >
          <Button
            sx={{
              marginTop: '10px',
              backgroundColor: '#17bebb',
            }}
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
          >
            Cadastrar
          </Button>

          <Button
            sx={{ marginTop: '10px' }}
            variant="outlined"
            color="error"
            onClick={handleClickOpenDialogCancel}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
};
